import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { NgModule, Injector, ModuleWithProviders } from '@angular/core';
import { Store } from '@ngrx/store';

import { InstanceManagerService } from '../services/instance-manager/instance-manager.service';
import { I18N_FILE, I18N_SCOPE, EI18nScope } from '../models/core.model';
import { I18nService } from '../services/i18n/i18n.service';
import { selectLang } from '../store/core.selectors';

/**
 * Translate loader provider export const
 */
export const TranslateLoaderProvider = {
	provide: TranslateLoader,
	useClass: I18nService,
	deps: [Injector],
};

/**
 * Initializes language files
 *
 * @param _injector injector
 * @param root root boolean
 */
export const loadLangFiles = (_injector: Injector, root = false): void => {
	const moduleName = _injector.get(I18N_FILE);
	if (root) {
		_injector.get(InstanceManagerService).setRootInstance(_injector.get(TranslateService));
	} else {
		_injector.get(InstanceManagerService).setFeatureInstance(moduleName, _injector.get(TranslateService));
	}
	_injector
		.get(Store)
		.select(selectLang)
		.subscribe({
			next: (lang: string | undefined) => {
				if (lang) {
					_injector.get(TranslateService).use(lang);
				}
			},
		});
};

/**
 * Ng module for root
 */
@NgModule({
	imports: [TranslateModule.forRoot({ isolate: true })],
	providers: [TranslateLoaderProvider],
	exports: [TranslateModule],
})
export class I18nModuleForRoot {
	/**
	 * Loads language files for root
	 *
	 * @param _injector injector
	 */
	constructor(private _injector: Injector) {
		loadLangFiles(_injector, true);
	}
}

/**
 * Ng module for child
 */
@NgModule({
	imports: [TranslateModule.forChild({ isolate: true })],
	providers: [TranslateLoaderProvider],
	exports: [TranslateModule],
})
export class I18nModuleForChild {
	/**
	 * Loads language files for child
	 *
	 * @param _injector
	 */
	constructor(private _injector: Injector) {
		loadLangFiles(_injector);
	}
}

/**
 * Main Ng module
 */
@NgModule()
export class I18nModule {
	/**
	 * Sets the providers for root
	 *
	 * @param fileName file name
	 * @returns module and providers
	 */
	static forRoot(fileName: string): ModuleWithProviders<any> {
		return {
			ngModule: I18nModuleForRoot,
			providers: [
				{
					provide: I18N_SCOPE,
					useValue: EI18nScope.FOR_ROOT,
				},
				{
					provide: I18N_FILE,
					useValue: fileName,
				},
			],
		};
	}

	/**
	 * Sets the providers for feature
	 *
	 * @param fileName file name
	 * @returns module and providers
	 */
	static forFeature(fileName: string): ModuleWithProviders<any> {
		return {
			ngModule: I18nModuleForChild,
			providers: [
				{
					provide: I18N_SCOPE,
					useValue: EI18nScope.FOR_CHILD,
				},
				{
					provide: I18N_FILE,
					useValue: fileName,
				},
			],
		};
	}
}
