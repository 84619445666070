import { Component } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Error 500 component
 */
@Component({
	selector: 'imv-error500',
	templateUrl: './error500.component.html',
	styleUrls: ['./error500.component.scss'],
})
export class Error500Component {
	/**
	 * Error object reference
	 */
	error: any;
	/**
	 * Error codes reference
	 */
	errors = ['500', '001', '002', '003'];

	/**
	 * Sets error
	 *
	 * @param router
	 */
	constructor(private router: Router) {
		this._setError();
	}

	/**
	 * Navigates to home by url
	 */
	navigateHome() {
		this.router.navigateByUrl('/');
	}

	/**
	 * Set error code and error state
	 */
	private _setError() {
		const state: any = this.router.getCurrentNavigation()?.extras.state;
		const errorCode = state && this.errors.includes(state.code) ? state.code : '500';
		this.error = {
			title: `shared.error.title-${errorCode}`,
			message: `shared.error.message-${errorCode}`,
		};
	}
}
