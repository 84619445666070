import { Injectable } from '@angular/core';
import { ValidationError } from '@shared/models';
import { Observable } from 'rxjs';
import { CommonModalsService } from './common-modals.service';

/**
 * Common modals abstract service
 */
@Injectable({
	providedIn: 'root',
	useClass: CommonModalsService,
	deps: [],
})
export abstract class AbstractCommonModalsService {
	/**
	 * Validation error modal observable
	 */
	abstract validationErrorModalObservable(): Observable<ValidationError>;

	/**
	 * Go Home modal observable
	 */
	abstract goHomeModalObservable(): Observable<void>;

	/**
	 * General error modal observable
	 */
	abstract generalErrorModalObservable(): Observable<string>;

	/**
	 * Document loading modal observable
	 */
	abstract documentLoadingModalObservable(): Observable<string>;

	/**
	 * Draft version modal observable
	 */
	abstract draftVersionModalObservable(): Observable<void>;

	/**
	 * General error close modal observable
	 */
	abstract generalErrorCloseModalObservable(): Observable<void>;

	/**
	 * Validation error modal event
	 *
	 * @param error validation error
	 */
	abstract validationErrorModalEvent(error: ValidationError): void;

	/**
	 * Go Home modal event
	 */
	abstract goHomeModalEvent(): void;

	/**
	 * General error modal event
	 */
	abstract generalErrorModalEvent(error: string): void;

	/**
	 * Document loading modal event
	 */
	abstract documentLoadingModalEvent(ev: string): void;

	/**
	 * Draft version modal event
	 */
	abstract draftVersionModalEvent(): void;

	/**
	 * General error close modal event
	 */
	abstract generalErrorCloseModalEvent(): void;
}
