import { createAction, props } from '@ngrx/store';
import { NavigationConfig } from '../models/shared.model';

import { ESharedActions, ENavigationActions } from './shared.state';

/**
 * Public. Init action
 */
export const init = createAction(ESharedActions.INIT);

/**
 * Public. Navigation configuration
 */
export const navigationConfig = createAction(ENavigationActions.CONFIG, props<{ payload: NavigationConfig }>());

/**
 * Public. Completes the step
 */
export const completeStep = createAction(ESharedActions.COMPLETE_STEP, props<{ payload: number }>());

/**
 * Public. Saves the request id
 */
export const saveRequestId = createAction(ESharedActions.SAVE_REQUEST_ID, props<{ id: string }>());

/**
 * Public. Saves the form responses
 */
export const saveFormResponses = createAction(ESharedActions.SAVE_FORM_RESPONSES, props<{ responses: any }>());

/**
 * Public. Saves the expedient
 */
export const saveExpedient = createAction(ESharedActions.SAVE_expedient, props<{ responses: any }>());

/**
 * Public. Resets the form
 */
export const resetForm = createAction(ESharedActions.RESET_FORM);

// Private
