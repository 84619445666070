import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { I18nModule } from '@app/core';
import { ImvUiBoxLink, ImvUiJumbotron } from '@app/ui-kit';

import { HomeComponent } from './component/home.component';
import { HomeRoutingModule } from './home-routing.module';
import { MODULE_NAME } from './home.constants';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormControlModule } from '@shared/components';

import { CommonListsModule, CommonQuestionnaireModule } from '@shared/services';

@NgModule({
	declarations: [HomeComponent, ImvUiBoxLink, ImvUiJumbotron],
	imports: [CommonModule, HomeRoutingModule, SharedModule, I18nModule.forFeature(MODULE_NAME), CommonQuestionnaireModule, FormControlModule, CommonListsModule],
	exports: [HomeComponent],
	entryComponents: [HomeComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HomeModule {}
