<imv-ui-modal #modal [isMobile]="isMobile" type="fail">
	<imv-ui-button eid="confirm-close" theme="secondary" variant=" mdc-button--secondary mdc-button--modal" (click)="close()">
		<span slot="text">{{ action }}</span>
	</imv-ui-button>
	<h1 slot="title" id="confirm-title">{{ title }}</h1>
	<p slot="text" id="confirm-description">{{ description }}</p>
	<div slot="actions">
		<imv-ui-button eid="confirm-cancel" theme="secondary" variant=" mdc-button--secondary mdc-button--modal" (click)="close()">
			<span slot="text">{{ cancel }}</span>
		</imv-ui-button>
		<imv-ui-button eid="confirm-accept" theme="primary" variant=" mdc-button--primary mdc-button--modal" (click)="accept()">
			<span slot="text">{{ action }}</span>
		</imv-ui-button>
	</div>
</imv-ui-modal>
