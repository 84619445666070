import { Injectable } from '@angular/core';
import { ValidationError } from '@shared/models';
import { Observable, Subject } from 'rxjs';

import { AbstractCommonModalsService } from './abstract-common-modals.service';

/**
 * Common modals service
 */
@Injectable()
export class CommonModalsService implements AbstractCommonModalsService {
	/**
	 * Validation error modal
	 */
	private validationErrorModalSubject$ = new Subject<any>();

	/**
	 * Go Home modal
	 */
	private goHomeModalSubject$ = new Subject<void>();

	/**
	 * General error
	 */
	private generalErrorSubject$ = new Subject<any>();

	/**
	 * Document loading
	 */
	private documentLoadingSubject$ = new Subject<any>();

	/**
	 * Draft version
	 */
	private draftVersionSubject$ = new Subject<any>();

	/**
	 * General error close
	 */
	private generalErrorCloseSubject$ = new Subject<any>();

	/**
	 * Empty constructor
	 */
	constructor() {
		/**
		 * Empty constructor
		 */
	}

	/**
	 * Validation error modal obervable
	 *
	 * @returns validation error modal obervable
	 */
	public validationErrorModalObservable(): Observable<ValidationError> {
		return this.validationErrorModalSubject$.asObservable();
	}

	/**
	 * Go Home modal obervable
	 *
	 * @returns Go Home modal obervable
	 */
	public goHomeModalObservable(): Observable<void> {
		return this.goHomeModalSubject$.asObservable();
	}

	/**
	 * General error modal obervable
	 *
	 * @returns general error modal obervable
	 */
	public generalErrorModalObservable(): Observable<string> {
		return this.generalErrorSubject$.asObservable();
	}

	/**
	 * Document loading modal obervable
	 *
	 * @returns document loading modal obervable
	 */
	public documentLoadingModalObservable(): Observable<string> {
		return this.documentLoadingSubject$.asObservable();
	}

	/**
	 * Draft version modal obervable
	 *
	 * @returns draft version modal obervable
	 */
	public draftVersionModalObservable(): Observable<any> {
		return this.draftVersionSubject$.asObservable();
	}

	/**
	 * General error modal obervable
	 *
	 * @returns general error modal obervable
	 */
	public generalErrorCloseModalObservable(): Observable<any> {
		return this.generalErrorCloseSubject$.asObservable();
	}

	/**
	 * Validation error modal event
	 *
	 * @param error validation error
	 */
	validationErrorModalEvent(error: ValidationError): void {
		this.validationErrorModalSubject$.next(error);
	}

	/**
	 * Go Home modal event
	 */
	goHomeModalEvent(): void {
		this.goHomeModalSubject$.next();
	}

	/**
	 * General error modal event
	 */
	generalErrorModalEvent(error: string): void {
		this.generalErrorSubject$.next(error);
	}

	/**
	 * Document error modal event
	 */
	documentLoadingModalEvent(ev: string): void {
		this.documentLoadingSubject$.next(ev);
	}

	/**
	 * Draft version modal event
	 */
	draftVersionModalEvent(): void {
		this.draftVersionSubject$.next({});
	}

	/**
	 * General error close modal event
	 */
	generalErrorCloseModalEvent(): void {
		this.generalErrorCloseSubject$.next({});
	}
}
