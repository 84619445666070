import { Component } from '@angular/core';

/**
 * Maintenance page component
 */
@Component({
	selector: 'imv-maintenance',
	templateUrl: './maintenance.component.html',
	styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent {
	/**
	 * Empty constructor
	 */
	constructor() {
		/**
		 * Empty constructor
		 */
	}

}
