import { FormField } from './form-field.model';
import { FormGroup } from './form-group.model';
import { FormBlockDto } from '../dtos/form.dto';

/**
 * Form block model
 */
export class FormBlock implements FormBlockDto {
	[key: string]: any;
	/**
	 * Title
	 */
	titulo!: string;
	/**
	 * Subtitle
	 */
	subtitulo!: string;
	/**
	 * Condition
	 */
	condicion!: string;
	/**
	 * Layout
	 */
	layout!: string;
	/**
	 * Repeat item
	 */
	repetirN!: string;
	/**
	 * Context
	 */
	contexto!: string;
	/**
	 * Groups array
	 */
	grupos: Array<FormGroup>;

	/**
	 * Has condition boolean
	 */
	hasCondition: boolean;

	/**
	 * Cards array
	 */
	cards: Array<any>;

	/**
	 * Is visible boolean
	 */
	isVisible: boolean;
	/**
	 * Is multiple boolean
	 */
	isMultiple: boolean;
	/**
	 * Children array
	 */
	children: Array<FormBlock>;
	/**
	 * Index number
	 */
	index: number;

	/**
	 * Constructor. Initializes the booleans and arrays
	 */
	constructor() {
		this.isVisible = true;
		this.isMultiple = false;
		this.hasCondition = false;
		this.grupos = [];
		this.cards = [];
		this.children = [];
		this.index = 0;
	}

	/**
	 * Formats a form block
	 *
	 * @param data backend format form block
	 * @returns formatted form block
	 */
	static fromDto(data: FormBlockDto): FormBlock {
		const obj = new FormBlock();

		obj.titulo = data.titulo || '';
		obj.subtitulo = data.subtitulo || '';
		obj.condicion = data.condicion.trim() || '';
		obj.layout = data.layout || '';
		obj.repetirN = data.repetirN.trim() || '';
		obj.contexto = data.contexto.trim() || '';

		if (['docs-titular', 'docs-beneficiarios'].includes(obj.contexto)) {
			obj.layout = 'panel';
		}

		if (obj.repetirN !== '') {
			obj.isMultiple = true;
		}

		if (obj.isMultiple && obj.layout !== 'panel') {
			obj.layout = 'modal';
		}

		if (obj.condicion) {
			obj.isVisible = false;
			obj.hasCondition = true;
		}

		const groups = data.grupos || [];
		for (const group of groups) {
			obj.grupos.push(FormGroup.fromDto(group));
		}

		return obj;
	}

	/**
	 * Checks if the group has visible content
	 *
	 * @returns true if the group has visible content
	 */
	hasContentVisible(): boolean {
		if (!this.isVisible) {
			return false;
		}

		return this.grupos.some(group => group.hasContentVisible());
	}

	/**
	 * Create cloned blocks to repeat the current block.
	 *
	 * Clone groups and create all fields with key format `{parentKey}_[index}`
	 *
	 * @param count Number of repetitions
	 */
	repeat(count: number) {
		const cloneKeys = ['idBlock', 'titulo', 'subtitulo', 'condicion', 'layout', 'repetirN', 'contexto', 'hasCondition', 'isVisible'];

		this.isMultiple = true;
		this.cards = [];
		this.children = [];

		for (let i = 1; i <= count; i++) {
			this.cards.push(i);

			const block = new FormBlock();
			block.isMultiple = true;
			block.index = i;
			for (const key of cloneKeys) {
				block[key] = this[key];
			}

			for (const group of this.grupos) {
				block.grupos.push(group.clone(i));
			}

			this.children.push(block);
		}
	}

	/**
	 * Gets the card content
	 *
	 * @param index index number
	 * @param responses responses
	 * @returns card content
	 */
	getCardContent(index: number, responses: any) {
		const block = this.children[index - 1];
		const fields = block.getAllFields();
		const values = [];

		for (let i = 0; i < 3; i++) {
			if (fields.length > i + 1 && responses[fields[i].key]) {
				values.push(responses[fields[i].key]);
			}
		}

		return values.join(' ').trim();
	}

	/**
	 * Gets all the fields
	 *
	 * @returns fields array
	 */
	getAllFields(): FormField[] {
		const fields: FormField[] = [];

		if (this.children.length) {
			for (const block of this.children) {
				this.addFields(fields, block.grupos);
			}
		} else {
			this.addFields(fields, this.grupos);
		}

		return fields;
	}

	private addFields(fields: FormField[], grupos: FormGroup[]) {
		for (const group of grupos) {
			for (const field of group.campos) {
				if (field.children.length) {
					for (const child of field.children) {
						fields.push(child);
					}
				} else {
					fields.push(field);
				}
			}
		}
	}

	/**
	 * Gets the default layout
	 */
	get isDefaultLayout() {
		return this.layout.trim() === '';
	}

	/**
	 * Return true if it is a modal
	 */
	get isModal() {
		return this.layout === 'modal';
	}

	/**
	 * Returns true if it is a panel
	 */
	get isPanel() {
		return this.layout === 'panel';
	}
}
