/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActionDialogInterface } from './ActionDialogInterface';

@Injectable({
	providedIn: 'root',
})
export class DialogsService {
	handleError: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
	action: BehaviorSubject<ActionDialogInterface | null> = new BehaviorSubject<ActionDialogInterface | null>(null);
	doCommand = (action: ActionDialogInterface | null, errorCode: string, command: string, callback = () => {}): void => {
		if (action && action.errorCode === errorCode && action.action === command) {
			callback();
		}
	};
}
