import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenanceComponent } from './maintenance.component';

const COMPONENTS = [
	MaintenanceComponent,
];
@NgModule({
	declarations: [
		...COMPONENTS
	],
	imports: [
		CommonModule,
	],
	exports: [...COMPONENTS
	],
})
export class MaintenanceModule { }