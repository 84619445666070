import { Component, isDevMode, OnInit, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Error 404 component
 */
@Component({
	selector: 'imv-error404',
	templateUrl: './error404.component.html',
	styleUrls: ['./error404.component.scss'],
})
export class Error404Component implements OnInit {
	/**
	 * Contains all data
	 *
	 * @param _router
	 * @param sanitizer
	 */
	constructor(private _router: Router, private sanitizer: DomSanitizer) {}

	/**
	 * Called to initialize a component and views, set url to /404
	 */
	ngOnInit(): void {
		if (!isDevMode()) {
			const actualUrl = window.location.href;
			const targetURL = this.sanitizeURL(actualUrl.replace('/404', '/custom404'));
			if (targetURL) {
				window.open(targetURL, '_self', 'noopener');
			}
		}
	}

	/**
	 * Navigates to home page
	 */
	navigateHome() {
		this._router.navigateByUrl('/');
	}

	/**
	 * Sanitizes url
	 *
	 * @param url
	 * @returns
	 */
	sanitizeURL(url: string): string | null {
		return this.sanitizer.sanitize(SecurityContext.URL, url);
	}
}
