import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RecoveryRequest, RecoveryRequestResponse, RecoveryToken, RecoveryTokenResponse, ExpedientResponse } from '@shared/models';
import { CommonConsultApiModule } from './common-consult-api.module';
import { CommonConsultService } from './common-consult.service';

/**
 * Common Consult abstract service
 */
@Injectable({
	providedIn: CommonConsultApiModule,
	useClass: CommonConsultService,
	deps: [Injector],
})
export abstract class AbstractCommonConsultService {
	/**
	 * Recovers claim draft token
	 *
	 * @param responses responses
	 */
	abstract recoverDraftToken(responses: RecoveryToken): Observable<RecoveryTokenResponse>;

	/**
	 * Recovers request token
	 *
	 * @param responses recovery token
	 */
	abstract recoverRequestToken(responses: RecoveryToken): Observable<RecoveryTokenResponse>;

	/**
	 * Recovers the consult
	 *
	 * @param responses recovery request
	 */
	abstract recoverConsult(responses: RecoveryRequest): Observable<RecoveryRequestResponse>;

	/**
	 * Recovers the consult
	 *
	 * @param responses recovery request
	 */
	abstract recoverRequest(responses: RecoveryRequest): Observable<RecoveryRequestResponse>;

	/**
	 * Recovers the expediente
	 *
	 * @param responses recovery request
	 */
	abstract recoverExpedient(responses: RecoveryRequest): Observable<ExpedientResponse>;

	/**
	 * Recovers the expediente
	 *
	 * @param responses recovery request
	 */
	abstract recoverExpedientCaiss(body: any): Observable<ExpedientResponse>;

	/**
	 * Gets the status code of the expedient
	 *
	 * @param idFuncional id funcional
	 */
	abstract statusCodeExpediente(idFuncional: string): Observable<any>;

	/**
	 * Gets the status message of the expedient
	 *
	 * @param indSubEstado subestado index
	 */
	abstract statusMessageExpediente(indSubEstado: string): Observable<any>;

	/**
	 * Uploads sanity documents
	 *
	 * @param documents documents
	 */
	abstract uploadSanityDocuments(documents: any): Observable<any>;

	/**
	 * Submits right option from citizen
	 *
	 * @param body request body
	 */
	abstract submitRightOpt(body: any): Observable<any>;

	/**
	 * Submits right option from CAISS
	 *
	 * @param body request body
	 */
	abstract submitRightOptCaiss(body: any): Observable<any>;

	/**
	 * Submits the right option file
	 *
	 * @param body request body
	 */
	abstract submitRightOptFile(body: any): Observable<any>;

	/**
	 * Gets the right option receipt
	 *
	 * @param body request body
	 */
	abstract getRightOptReceipt(body: any): Observable<any>;

	/**
	 * Gets the IMV receipt
	 *
	 * @param idSolicitud claim id
	 */
	abstract getImvReceipt(idSolicitud: string): Observable<any>;

	/**
	 * Gets the modifications receipt
	 *
	 * @param body request body
	 */
	abstract getModificationsReceipt(body: any): Observable<any>;

	/**
	 * Gets the subsanation messages
	 *
	 * @param body request body
	 */
	abstract getSubsanationMessages(body: any): Observable<any>;

	/**
	 * Submits the identification diligence
	 *
	 * @param idFuncional id funcional
	 * @param option option
	 */
	abstract submitIdentificationDiligence(idFuncional: string, option: string): Observable<any>;

	/**
	 * Submits the identification diligence file
	 *
	 * @param body request body
	 */
	abstract submitIdentificationDiligenceFile(body: any): Observable<any>;

	/**
	 * Sends a claim response which was not included in the original form version
	 *
	 * @param body request body
	 */
	abstract sendClaimResponse(body: any): Observable<any>;

	/**
	 * Gets the citizen expedients
	 *
	 * @param body request body with document number
	 * @returns expedient list
	 */
	abstract getCitizenExpedients(body: any): Observable<any>;

	/**
	 * Gets a expedient by id
	 *
	 * @param body request body with expedient id
	 * @returns expedient
	 */
	abstract recoverExpedientById(body: any): Observable<any>;

	/**
	 * Gets the email of a expediente by DNI
	 *
	 * @param body DNI associated to the request
	 * @returns email associated
	 */
	abstract recoverEmailByDocument(body: any): Observable<any>;
}
