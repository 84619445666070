import { createSelector } from '@ngrx/store';
import { AppStore } from '@shared/models';

import { IIdentificationStore, featureStoreName } from './identification.state';

/**
 * Identification selectors
 */
export interface IFeatureAppStore extends AppStore {
	/**
	 * Feature store name
	 */
	[featureStoreName]: IIdentificationStore;
}

/**
 * Identification store selector
 *
 * @param state feature app store
 * @returns state
 */
export const selectIdentificationStore = (state: IFeatureAppStore) => state[featureStoreName];

/**
 * User selector
 */
export const selectUser = createSelector(selectIdentificationStore, (state: IIdentificationStore) => state?.user);

/**
 * Select first name selector
 */
export const selectFirstName = createSelector(selectIdentificationStore, (state: IIdentificationStore) => state?.user?.firstName);
