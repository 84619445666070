import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { v4 } from 'uuid';

@NgModule({
	declarations: [],
	imports: [CommonModule],
})
export class UuidModule {
	static v4 = v4;
}
