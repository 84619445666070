import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { AbstractCommonNavigationService } from './abstract-common-navigation.service';

/**
 * Common-Navigation service
 */
@Injectable()
export class CommonNavigationService implements AbstractCommonNavigationService {
	/**
	 * Next button subject
	 */
	private nextButton$ = new Subject<boolean>();
	/**
	 * Previous button subject
	 */
	private previousButton$ = new Subject<boolean>();
	/**
	 * Cancel button subject
	 */
	private cancelButton$ = new Subject<boolean>();
	/**
	 * Submit button subject
	 */
	private submitButton$ = new Subject<boolean>();
	/**
	 * Progressbar subject
	 */
	private progressBar$ = new Subject<number>();
	/**
	 * Progress value
	 */
	private progress = 0;
	/**
	 * Initial progress in form value
	 */
	private inititalProgressInForm = 44;

	/**
	 * Empty Constructor
	 */
	constructor() {
		/**
		 * Empty Constructor
		 */
	}

	/**
	 * Converts subject next button as observable
	 *
	 * @returns
	 */
	public next(): Observable<boolean> {
		return this.nextButton$.asObservable();
	}

	/**
	 * Converts subject previous button as observable
	 *
	 * @returns
	 */
	public previous(): Observable<boolean> {
		return this.previousButton$.asObservable();
	}

	/**
	 * Converts subject cancel button as observable
	 *
	 * @returns
	 */
	public cancel(): Observable<boolean> {
		return this.cancelButton$.asObservable();
	}

	/**
	 * Converts subject submit button as observable
	 *
	 * @returns
	 */
	public submit(): Observable<boolean> {
		return this.submitButton$.asObservable();
	}

	/**
	 * Converts subject progressbar button as observable
	 *
	 * @returns
	 */
	public progressBar(): Observable<number> {
		return this.progressBar$.asObservable();
	}

	/**
	 * Sets progresbar to new value passed by param
	 *
	 * @param value value number
	 */
	public setProgressBar(value: number): void {
		if (value >= 0 && value <= 100 && value !== this.progress) {
			this.progress = value;
			this.progressBar$.next(value);
		}
	}

	/**
	 * Sets progressbar in questionnaire
	 *
	 * @param isIncrement is increment boolean
	 */
	public setProgressBarInQuestionnaire(isIncrement: boolean) {
		if (isIncrement) {
			if (this.progress >= 31) {
				this.setProgressBar(33);
			} else {
				this.setProgressBar(this.progress + 2);
			}
		} else {
			this.setProgressBar(this.progress - 2);
		}
	}

	/**
	 * Sets progressbar in form. The pages number const is the total steps number plus the summary page
	 *
	 * @param currentStep current form step
	 * @param totalSteps total number of steps
	 */
	public setProgressBarInForm(currentStep: number, totalSteps: number) {
		const pagesNumber = totalSteps + 1;
		let progress = this.inititalProgressInForm + Math.floor((currentStep / pagesNumber) * (100 - this.inititalProgressInForm));
		if (totalSteps === currentStep) {
			progress = 90;
		}
		this.setProgressBar(progress);
	}

	/**
	 * Emits a change on next button subject
	 */
	nextButton(): void {
		this.nextButton$.next(true);
	}

	/**
	 * Emits a change on previous button subject
	 */
	previousButton(): void {
		this.previousButton$.next(true);
	}

	/**
	 * Emits a change on cancel button subject
	 */
	cancelButton(): void {
		this.cancelButton$.next(true);
	}

	/**
	 * Emits a change on submit button subject
	 */
	submitButton(): void {
		this.submitButton$.next(true);
	}
}
