import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { CommonListsApiModule } from './common-lists-api.module';
import { ICoexistencePlanResponse } from './common-lists.model';
import { CommonListsService } from './common-lists.service';

/**
 * Common lists abstract service
 */
@Injectable({
	providedIn: CommonListsApiModule,
	useClass: CommonListsService,
	deps: [Injector],
})
export abstract class AbstractCommonListsService {
	/**
	 * Gets the countries
	 */
	abstract getAllCountries(): Observable<any>;

	/**
	 * Gets the provinces
	 */
	abstract getAllProvinces(): Observable<any>;

	/**
	 * Gets the pronvices. Full endpoint
	 */
	abstract getAllProvincesFull(): Observable<any>;

	/**
	 * Gets the address
	 */
	abstract getAllAddress(): Observable<any>;

	/**
	 * Gets the towns by province
	 *
	 * @param province province
	 */
	abstract getTowns(province: string): Observable<any>;

	/**
	 * Gets the postal codes by province
	 *
	 * @param province province
	 */
	abstract getPostalCodes(province: string): Observable<any>;

	/**
	 * Gets the marital status
	 */
	abstract getAllMaritalStatus(): Observable<any>;

	/**
	 * Gets the accommodation types
	 */
	abstract getAccommodationTypes(): Observable<any>;

	/**
	 * Gets the Entidades gestoras provinces
	 */
	abstract getAllProvincesSS(): Observable<any>;

	/**
	 * Gets the redirect URL
	 *
	 * @param urlParam entry URL parameter
	 */
	abstract getRedirectUrl(urlParam: string): Observable<any>;

	/**
	 * Checks if the province is pilot
	 *
	 * @param province province
	 */
	abstract isPilotProvince(province: string): Observable<ICoexistencePlanResponse>;

	/**
	 * Access to proceeding page by token
	 *
	 * @param token token
	 */
	abstract accessProceedings(token: string): Observable<ICoexistencePlanResponse>;

	/**
	 * Access to draft by token
	 *
	 * @param token token
	 */
	abstract accessDraft(token: string): Observable<ICoexistencePlanResponse>;
}
