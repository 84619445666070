import { FormBlock } from './form-block.model';
import { FormStepDto } from '../dtos/form.dto';

/**
 * Form type
 */
export type Form = Array<FormStep>;

/**
 * Form Step model
 */
export class FormStep implements FormStepDto {
	/**
	 * Step id
	 */
	idPaso!: string;
	/**
	 * Title
	 */
	titulo!: string;
	/**
	 * Subtitle
	 */
	subtitulo!: string;
	/**
	 * Condition
	 */
	condicion!: string;
	/**
	 * Context
	 */
	contexto!: string;
	/**
	 * Introdution
	 */
	introduccion!: string;
	/**
	 * Form blocks array
	 */
	bloques: Array<FormBlock>;

	/**
	 * Is visible boolean
	 */
	isVisible: boolean;
	/**
	 * Has condition boolean
	 */
	hasCondition: boolean;
	/**
	 * Step number
	 */
	step!: number;

	/**
	 * Constructor. Initializes the booleans and array
	 */
	constructor() {
		this.isVisible = true;
		this.hasCondition = false;
		this.bloques = [];
	}

	/**
	 * Formats a form step
	 *
	 * @param data backend formatted form step
	 * @param step step number
	 * @returns formatted form step
	 */
	static fromDto(data: FormStepDto, step: number): FormStep {
		const obj = new FormStep();

		obj.idPaso = data.idPaso;
		obj.titulo = data.titulo || '';
		obj.subtitulo = data.subtitulo || '';
		obj.contexto = data.contexto || '';
		obj.introduccion = data.introduccion || '';
		obj.condicion = data.condicion || '';

		obj.step = step;

		if (obj.condicion) {
			obj.isVisible = false;
			obj.hasCondition = true;
		}

		const blocks = data.bloques || [];
		for (const block of blocks) {
			obj.bloques.push(FormBlock.fromDto(block));
		}

		return obj;
	}

	/**
	 * Gets the form blocks
	 *
	 * @param ignoreModal ignore modal boolean
	 * @returns form blocks array
	 */
	getBloques(ignoreModal = true): FormBlock[] {
		const result = [];

		for (const block of this.bloques) {
			if ((block.isModal && ignoreModal) || block.children.length === 0) {
				result.push(block);
			} else {
				for (const child of block.children) {
					result.push(child);
				}
			}
		}

		return result;
	}
}
