import { createSelector } from '@ngrx/store';
import { AppStore } from '@shared/models';

import { IHomeStore, featureStoreName } from './home.state';

/**
 * Questionnaire Home selectors
 */
export interface IFeatureAppStore extends AppStore {
	/**
	 * Feature store name
	 */
	[featureStoreName]: IHomeStore;
}

/**
 * Home store selector
 *
 * @param state feature app store
 * @returns state
 */
export const selectHomeStore = (state: IFeatureAppStore) => state[featureStoreName];

/**
 * Questions selector
 */
export const selectQuestions = createSelector(selectHomeStore, (state: IHomeStore) => state?.questions);

/**
 * Current selector
 */
export const selectCurrent = createSelector(selectHomeStore, (state: IHomeStore) => state?.current);

/**
 * Home state selector
 */
export const selectHomeState = createSelector(selectHomeStore, (state: IHomeStore) => state);
