/* eslint-disable max-len */
import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAppConfig } from '../../models/core.model';
import { GlobalPubSub } from './global-pub-sub.service';
import { FEATURE_TOGGLES } from './FEATURE_TOGGLES';
@Injectable({
	providedIn: 'root',
})
export class FeatureToggleService extends GlobalPubSub {
	private readonly PATH_TO_FEATURE_TOGGLE_SERVICE = 'tablas-parametricas/provincia';
	private readonly MODE_SANDBOX = true;
	private SANDBOX: any = FEATURE_TOGGLES;
	features: BehaviorSubject<any> = new BehaviorSubject(null);
	constructor(protected ngZone: NgZone) {
		super(ngZone);
	}
	loadFeatures = (config: IAppConfig): Observable<any> => {
		const pathToFeatureToggleService = `${config.apiHost.bff}/${this.PATH_TO_FEATURE_TOGGLE_SERVICE}`;
		console.log('pathToFeatureToggleService', pathToFeatureToggleService);
		return of(FEATURE_TOGGLES).pipe(map(this.useSandbox)).pipe(map(this.setFeatures));
	};
	getFeature = (feature: any) => this.features.value[feature];
	loadFeatureToggle = (feature: string, scope: any): any => this.features.subscribe(features => (scope[feature] = features ? features[feature] : ''));
	private setFeatures = (features: any) => {
		this.features.next(features);
		this.setSubscriptions(features);
		return features;
	};
	private useSandbox = (res: any) => (this.MODE_SANDBOX ? this.SANDBOX : res);
	private setSubscriptions = (features: any) =>
		Object.keys(features).forEach(feature =>
			this.subscribe(feature, (value: any) => {
				this.features.value[feature] = value;
				return this.features.next(this.features.value);
			}),
		);
}
