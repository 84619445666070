import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PageComponent } from './page.component';
const PAGE_COMPONENTS = [PageComponent];
@NgModule({
	declarations: [...PAGE_COMPONENTS],
	exports: [...PAGE_COMPONENTS],
	imports: [CommonModule],
	schemas: [],
})
export class PageModule {}
