import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';

import { ECoreActions } from './core.state';
/**
 * Core Effects
 */
@Injectable()
export class CoreEffects {
	/**
	 * Initializes the CAISS login
	 */
	initLoginCaiss$ = createEffect(
		() =>
			this._actions$.pipe(
				ofType(ECoreActions.INIT_LOGIN_CAISS),
				tap(() => {
					this._service.redirectExternalLogin();
				}),
			),
		{ dispatch: false },
	);

	/**
	 * Core Effects constructor
	 *
	 * @param _actions$ actions
	 * @param _service auth service
	 */
	constructor(private _actions$: Actions, private _service: AuthService) {}
}
