import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { ImageControlComponent } from './image-control.component';

@NgModule({
	declarations: [ImageControlComponent],
	exports: [ImageControlComponent],
	imports: [CommonModule, SharedModule],
})
export class ImageControlModule {}
