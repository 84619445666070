<div class="imv-error">
	<div class="imv-error__content">
		<h1 class="imv-error__title">Página en mantenimiento</h1>
		<p class="imv-error__text">
			Por motivos de mantenimiento, no estará disponible el portal, estamos trabajando en solventar el problema lo antes posible. Sentimos los inconvenientes.
		</p>
	</div>
	<img class="imv-error__image" src="./assets/pwa/error/404-page-not-found-flatline.png" alt="Error imagen" />
</div>
<script>
	(function (w, d, s, l, i) {
		w[l] = w[l] || [];
		w[l].push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' });
		var f = d.getElementsByTagName(s)[0],
			j = d.createElement(s),
			dl = l != 'dataLayer' ? '&l=' + l : '';
		j.async = true;
		j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
		f.parentNode.insertBefore(j, f);
	})(window, document, 'script', 'dataLayer', 'GTM-5GSCLF6');
</script>
