import { FormField } from './form-field.model';
import { FormGroupDto } from '../dtos/form.dto';

/**
 * Form group model
 */
export class FormGroup implements FormGroupDto {
	/**
	 * Group id
	 */
	idGrupo!: string;

	/**
	 * Title
	 */
	titulo!: string;

	/**
	 * Subtitle
	 */
	subtitulo!: string;

	/**
	 * Condition
	 */
	condicion!: string;

	/**
	 * Layout
	 */
	layout!: string;

	/**
	 * Tooltip
	 */
	tooltip!: string;

	/**
	 * Fields array
	 */
	campos: Array<FormField>;

	/**
	 * Is visible boolean
	 */
	isVisible: boolean;

	/**
	 * Has condition boolean
	 */
	hasCondition: boolean;

	/**
	 * Constructor. Initializes the fields array, conditions and is visibible booleans
	 */
	constructor() {
		this.campos = [];
		this.hasCondition = false;
		this.isVisible = true;
	}

	/**
	 * Checks if the item has visible content
	 *
	 * @returns true if the item has visible content
	 */
	public hasContentVisible(): boolean {
		if (!this.isVisible) {
			return false;
		} else {
			for (const field of this.campos) {
				if (field.isVisible) {
					return true;
				}
			}
		}

		return false;
	}

	/**
	 * Gets all fields of the group with children included.
	 *
	 */
	public getCampos(): FormField[] {
		const fields = [];

		for (const field of this.campos) {
			if (field.isMultiple) {
				fields.push(...field.children);
			} else {
				fields.push(field);
			}
		}

		return fields;
	}

	/**
	 * Formats the form group data
	 *
	 * @param data form group with backend format
	 * @returns formatted form group
	 */
	static fromDto(data: FormGroupDto): FormGroup {
		const obj = new FormGroup();

		obj.idGrupo = data.idGrupo;
		obj.titulo = data.titulo || '';
		obj.subtitulo = data.subtitulo || '';
		obj.condicion = data.condicion || '';
		obj.layout = data.layout || '';
		obj.tooltip = data.tooltip || '';

		const fields = data.campos || [];
		for (const field of fields) {
			obj.campos.push(FormField.fromDto(field));
		}

		if (obj.condicion) {
			obj.hasCondition = true;
			obj.isVisible = false;
		}

		const year = new Date().getFullYear();
		obj.titulo = obj.titulo.replace('{anyo_actual}', `${year}`);
		obj.titulo = obj.titulo.replace('{anyo_anterior}', `${year - 1}`);
		obj.titulo = obj.titulo.replace('{anyo_anterior_1}', `${year - 2}`);

		return obj;
	}

	/**
	 * Clones the form group
	 *
	 * @param index index
	 * @returns cloned form group
	 */
	clone(index: any): FormGroupExt {
		const cloneKeys = ['idGrupo', 'titulo', 'subtitulo', 'condicion', 'layout', 'tooltip', 'hasCondition', 'isVisible'];

		const group: FormGroupExt = new FormGroup();
		for (const key of cloneKeys) {
			const value = this[key as keyof FormGroup];
			group[key] = value;
		}

		group.condicion = group.condicion.replace(/_index/g, `_${index}`);

		const fields = [];
		for (const field of this.campos) {
			fields.push(field.clone(index));
		}
		group.campos = fields;

		return group;
	}
}


interface FormGroupExt extends FormGroup {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}