import packageJson from '../../package.json';
/**
 * Export const to set the config file, language and production variable
 */
export const environment = {
	production: true,
	envVersion: `pro-${packageJson.version}`,
	configFile: 'configs/prod.json',
	defaultLang: 'es',
};
