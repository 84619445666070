import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { jwtDecode } from 'jwt-decode';

@NgModule({
	declarations: [],
	imports: [CommonModule],
})
export class JwtDecodeModule {
	static jwtDecode = jwtDecode;
}
