const FEATURE_TOGGLES: any = {};
const features = [
	{
		name: 'VASQUE_PROVINCE_DELEGATION',
		description: 'Muestra la delegación de las provincias vascas',
		value: true,
	},
	{
		name: 'NAVARRA_PROVINCE_DELEGATION',
		description: 'Muestra la delegación de la provincia de navarra',
		value: false,
	},
	{
		name: 'DATE_VALIDATIONS',
		description: 'Carga las validaciones de fechas',
		value: true,
	},
	{
		name: 'REQUIRED_CHILDREN_IDENTIFICATION',
		description: 'Muestra un mensaje de que el DNI para menores de 14 años es obligatorio',
		value: true,
	},
	{
		name: 'LANGUAGES_LIST',
		description: 'Muestra un seleccionable de los idiomas',
		value: false,
	},
	{
		name: 'DISABLE_81_84_NEW_PERIOD',
		description: 'Deshabilita las preguntas 81 y 84 en el periodo inicial 2024',
		value: true,
	},
	{
		name: 'ANALYTIC_PUSH_TITLE',
		description: 'Activa o desactiva el push del title de analytic',
		value: true,
	},
];
console.table(features);

features.forEach(ft => {
	FEATURE_TOGGLES[ft.name] = ft.value;
});

export { FEATURE_TOGGLES };
