import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonNavigationModule } from '@shared/services';
import { SharedModule } from '../../shared.module';

import { NavigationComponent } from './navigation.component';

import { TranslateModule } from '@ngx-translate/core';
import { I18nModule } from '@app/core';
import { UiComponentsModule } from '../../ui-components/ui-components.module';
@NgModule({
	declarations: [NavigationComponent],
	exports: [NavigationComponent],
	imports: [CommonModule, UiComponentsModule, SharedModule, CommonNavigationModule, TranslateModule, I18nModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NavigationModule { }
