import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	ImvUiInput,
	ImvUiButton,
	ImvUiSteps,
	ImvUiNote,
	ImvUiProgressbar,
	ImvUiModal,
	ImvUiSelectorList,
	ImvUiTooltip,
	ImvUiPanel,
	ImvUiAutocomplete,
	ImvUiCard,
	ImvUiUploadFile,
	ImvUiUploadImage,
	ImvUiVideoidentification,
	ImvUiHeader,
	ImvUiBreadcrumbs
} from '@app/ui-kit';
import { UiUpload } from '@imv/ui-kit/dist/types/components/upload-file/models/upload-file.model';
import { FileUpload } from '@imv/ui-kit/dist/types/components/upload-image/models/upload-image.model';
import { SelectOption } from '@imv/ui-kit/dist/types/components/input/models/input.model';

const IMV_COMPONENTS = [
	ImvUiInput,
	ImvUiButton,
	ImvUiSteps,
	ImvUiNote,
	ImvUiProgressbar,
	ImvUiModal,
	ImvUiSelectorList,
	ImvUiTooltip,
	ImvUiPanel,
	ImvUiAutocomplete,
	ImvUiCard,
	ImvUiUploadFile,
	ImvUiUploadImage,
	ImvUiVideoidentification,
	ImvUiHeader,
	ImvUiBreadcrumbs,
];

@NgModule({
	declarations: [...IMV_COMPONENTS],
	imports: [CommonModule],
	exports: [...IMV_COMPONENTS],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UiComponentsModule { }
export {
	ImvUiInput,
	ImvUiButton,
	ImvUiSteps,
	ImvUiNote,
	ImvUiProgressbar,
	ImvUiModal,
	ImvUiSelectorList,
	ImvUiTooltip,
	ImvUiPanel,
	ImvUiAutocomplete,
	ImvUiCard,
	ImvUiUploadFile,
	ImvUiUploadImage,
	ImvUiVideoidentification,
	// MODELS
	UiUpload,
	FileUpload,
	SelectOption,
};
