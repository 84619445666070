import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
	selector: 'imv-dialog-confirm',
	templateUrl: './dialog-confirm.component.html',
	styleUrls: ['./dialog-confirm.component.scss'],
})
export class DialogConfirmComponent extends DialogComponent implements OnInit, OnDestroy {
	override CUSTOM_ERRORS: string[] = ['JWT_MATCH'];
	cancel: string | undefined;

	override ngOnInit(): void {
		this.loadSubscriptions();
	}

	override setLabels = () => {
		const errorCode = this.error?.errorCode;
		if (errorCode && this.i18n[errorCode]) {
			const { title, description, action, cancel } = this.i18n[errorCode];
			this.title = title;
			this.description = description;
			this.action = action;
			this.cancel = cancel;
		} else {
			this.title = 'Ha ocurrido un error';
			this.description = this.error.message;
			this.action = 'Aceptar';
			this.action = 'Cancelar';
		}
	};

	accept = () => {
		const errorCode = this.error.errorCode;
		this.dialogEmitter('ACCEPT', errorCode);
		this.modal.close();
	};
}
