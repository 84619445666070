import { createSelector } from '@ngrx/store';

import { AppStore } from '../models/shared.model';
import { featureStoreName, ISharedStore } from './shared.state';

/**
 * Feature app store
 */
export interface IFeatureAppStore extends AppStore {
	/**
	 * Feature store name
	 */
	[featureStoreName]: ISharedStore;
}

/**
 * Shared store selector
 *
 * @param state feature app store
 * @returns state
 */
export const selectSharedStore = (state: IFeatureAppStore): any => state[featureStoreName];

/**
 * Step selector
 */
export const selectStep = createSelector(selectSharedStore, (state: ISharedStore): any => state?.step);

/**
 * Navigation selector
 */
export const selectNavigation = createSelector(selectSharedStore, (state: ISharedStore): any => state?.navigation);

/**
 * Steps selector
 */
export const selectSteps = createSelector(selectSharedStore, (state: ISharedStore): any => state?.steps);

/**
 * Request id selector
 */
export const selectRequestId = createSelector(selectSharedStore, (state: ISharedStore): any => state.savedRequestId);

/**
 * Form responses selector
 */
export const selectFormResponses = createSelector(selectSharedStore, (state: ISharedStore): any => state?.formResponses);

/**
 * Expedient selector
 */
export const selectExpedient = createSelector(selectSharedStore, (state: ISharedStore): any => state?.expedient);

/**
 * Form version selector
 */
export const selectFormVersion = createSelector(selectSharedStore, (state: ISharedStore): any => state?.expedient?.versionFormulario);

/**
 * First name from responses selector
 */
export const selectFirstNameFromResponses = createSelector(selectSharedStore, (state: ISharedStore): any => state?.formResponses?.['PI01']);
