import { IAppConfig } from '../models/core.model';

/**
 * Configuration manager
 */
export class ConfigManager {
	/**
	 * Application configuration interface
	 */
	private _config!: IAppConfig;
	/**
	 * Returns app configuration
	 */
	get config(): IAppConfig {
		return this._config;
	}

	/**
	 * Sets app configuration
	 *
	 * @param app congiguration value
	 */
	set config(value: IAppConfig) {
		this._config = value;
	}
}
