import { Component, ChangeDetectionStrategy, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Step } from '@shared/models';
import { selectSteps } from '@shared/store';
import { PageComponent } from '../page/page.component';

/**
 * Steps shared component
 */
@Component({
	selector: 'imv-steps',
	templateUrl: './steps.component.html',
	styleUrls: ['./steps.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepsComponent extends PageComponent {
	/**
	 * Steps array
	 */
	steps: Step[] = [];

	/**
	 * Steps observable
	 */
	steps$: Observable<Step[]> = this._store.select(<any>selectSteps);

	/**
	 * Steps component constructor
	 *
	 * @param _store store
	 */
	constructor(
		private _store: Store,
		_injector: Injector,
	) {
		super(_injector);
	}
}
