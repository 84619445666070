/**
 * Types of logLevel, from less to more verbose.
 */
enum LogLevel {
	None = 0,
	Error = 1,
	Warning = 2,
	Debug = 3,
}

/**
 * Auxiliar class for debugging purposes.
 * Groups the logs and filters the log levels.
 */
export class CoreLogger {
	/**
	 * Log level
	 */
	static level = LogLevel.None;

	/**
	 * Degub level. Shows all the activity.
	 *
	 * @param text Text to be logged.
	 * @param args Arguments to be logged.
	 */
	static debug(text: string, ...args: any[]) {
		if (CoreLogger.level >= LogLevel.Debug) {
			console.log(text, ...args);
		}
	}

	/**
	 * Used to show the warnings.
	 *
	 * @param text Text to be logged.
	 * @param args Arguments to be logged.
	 */
	static warning(text: string, ...args: any[]) {
		if (CoreLogger.level >= LogLevel.Warning) {
			console.warn(text, ...args);
		}
	}

	/**
	 * Used to show the errors.
	 *
	 * @param text Text to be logged.
	 * @param args Arguments to be logged.
	 */
	static error(text: string, ...args: any[]) {
		if (CoreLogger.level >= LogLevel.Error) {
			console.error(text, ...args);
		}
	}

	/**
	 * Used to group logs.
	 *
	 * @param label Group's label to be shown in the console.
	 * @param collapsed Whether the group should be initialy collapsed or not.
	 * @returns Used to exit the function without grouping if logLevel is below Debug.
	 */
	static group(label: string, collapsed = true) {
		if (CoreLogger.level < LogLevel.Debug) {
			return;
		}

		if (collapsed) {
			console.groupCollapsed(label);
		} else {
			console.group(label);
		}
	}

	/**
	 * Used to end a log group.
	 */
	static groupEnd() {
		console.groupEnd();
	}
}
