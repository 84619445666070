import { RefusalDto } from '../dtos/form.dto';

/**
 * List item model
 */
export interface ListItem {
	/**
	 * Id
	 */
	id: string;
	/**
	 * Name
	 */
	name: string;
	/**
	 * Is Spain boolean
	 */
	isEspania?: boolean;
	/**
	 * Is Europe boolean
	 */
	isEuropa?: boolean;
}

/**
 * Load list parameter
 */

export interface LoadListParameter {
	refusals: RefusalDto[];
	responses: any;
	form: any;
	countries: any[];
	provinces: any[];
	provincesFull: any[];
	addresses: any[];
	maritalStatus: any[];
	accommodationTypes: any[];
	provincesSS: any[];
}

/**
 * Lists class
 */
export class Lists {
	/**
	 * Countries array
	 */
	static countries: ListItem[] = [];
	/**
	 * Countries array without Spain
	 */
	static countriesWithoutSpain: ListItem[] = [];
	/**
	 * Provinces array
	 */
	static provinces: ListItem[] = [];
	/**
	 * Provinces full array
	 */
	static provincesFull: ListItem[] = [];
	/**
	 * Addresses array
	 */
	static addresses: ListItem[] = [];
	/**
	 * Marital status array
	 */
	static maritalStatus: ListItem[] = [];
	/**
	 * Accomodation types array
	 */
	static accommodationTypes: ListItem[] = [];
	/**
	 * Spanish id
	 */
	static spainId: string;
	/**
	 * Europe ids array
	 */
	static europeIds: string[] = [];
	/**
	 * SS Provinces array
	 */
	static provincesSS: ListItem[] = [];

	/**
	 * Loads the lists
	 *
	 * @param param0 countries, provinces, provincesFull, addresses, maritalStatus, accommodationTypes, provincesSS
	 */
	static load(loadListParameter: LoadListParameter) {
		Lists.loadCountries(loadListParameter.countries);
		Lists.countriesWithoutSpain = Lists.countries.filter(country => !country.isEspania || country.isEspania === null || country.isEspania === undefined);
		Lists.provinces = loadListParameter.provinces;
		Lists.provincesFull = loadListParameter.provincesFull;
		Lists.addresses = loadListParameter.addresses;
		Lists.maritalStatus = loadListParameter.maritalStatus;
		Lists.accommodationTypes = loadListParameter.accommodationTypes;
		Lists.provincesSS = loadListParameter.provincesSS;
	}

	/**
	 * Loads the countries
	 *
	 * @param countries countries array
	 */
	static loadCountries(countries: ListItem[]) {
		let spainId = '';
		const europeIds = [];

		for (const country of countries) {
			if (country.isEspania) {
				spainId = country.id;
			}
			if (country.isEuropa) {
				europeIds.push(country.id);
			}
		}

		Lists.countries = countries;
		Lists.spainId = spainId;
		Lists.europeIds = europeIds;
	}
}
