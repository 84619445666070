/* eslint-disable no-useless-escape */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CoreExpressions } from './core-expressions';
import { DateModule } from '../modules/date/date.module';
const { add, format, isAfter, isBefore, isMatch, isValid, parseISO } = DateModule;
/**
 * Prooves if a control field is empty
 *
 * @param control
 * @returns
 */
const isControlEmpty: (control: AbstractControl) => boolean = (control: AbstractControl) => (control.value || '') === '';

/**
 * Core validators
 */
export class CoreValidators {
	/**
	 * Validates DNI format
	 *
	 * @param control
	 * @returns
	 */
	static validateDni(control: AbstractControl): ValidationErrors | null {
		let error = null;
		if (isControlEmpty(control)) {
			return error;
		}

		const validChars = 'TRWAGMYFPDXBNJZSQVHLCKE';
		const str = control.value.toUpperCase();
		const letter = str.substr(-1);
		const charIndex = parseInt(str.substring(0, 8), 10) % 23;
		const exp = /^\d{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;

		if (!exp.test(str)) {
			error = { dni: true };
		}
		if (validChars.charAt(charIndex) !== letter) {
			error = { dni: true };
		}
		return error;
	}

	/**
	 * Validates NIE format
	 *
	 * @param control
	 * @returns
	 */
	static validateNie(control: AbstractControl): ValidationErrors | null {
		let error = null;
		if (isControlEmpty(control)) {
			return error;
		}
		const validChars = 'TRWAGMYFPDXBNJZSQVHLCKE';
		const str = control.value.toUpperCase();
		const document = str.replace('X', '0').replace('Y', '1').replace('Z', '2');
		const letter = str.substr(-1);
		const charIndex = parseInt(document.substring(0, 8), 10) % 23;
		const exp = /^[XYZ]\d{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
		if (!exp.test(str)) {
			error = { nie: true };
		}
		if (validChars.charAt(charIndex) !== letter) {
			error = { nie: true };
		}
		return error;
	}

	/**
	 * Validates support dni number format
	 *
	 * @param control
	 * @returns
	 */
	static validateSupportDni(control: AbstractControl): ValidationErrors | null {
		if (isControlEmpty(control)) {
			return null;
		}

		const str = control.value.toUpperCase();
		let error = null;
		const exp = /^[a-zA-Z]{3}\d{6}$/;
		if (!exp.test(str)) {
			error = { supportNumber: true };
		}
		return error;
	}

	/**
	 * Validates support nie number format
	 *
	 * @param control
	 * @returns
	 */
	static validateSupportNie(control: AbstractControl): ValidationErrors | null {
		if (isControlEmpty(control)) {
			return null;
		}

		const str = control.value.toUpperCase();
		let error = null;
		const exp = /^[EC]\d{8}$/;
		if (!exp.test(str)) {
			error = { supportNumber: true };
		}
		return error;
	}

	/**
	 * Validates date format
	 *
	 * @param control
	 * @returns
	 */
	static validateDate(control: AbstractControl): ValidationErrors | null {
		if (isControlEmpty(control)) {
			return null;
		}

		let error = null;
		const split = control.value.split('-');
		const x = new Date(split[0], split[1] - 1, split[2]);

		if (!isValid(x)) {
			error = { dateValid: true };
		}

		if (!isMatch(control.value, 'yyyy-MM-dd')) {
			error = { dateFormat: true };
		}

		return error;
	}

	/**
	 * Prooves that date is after today
	 *
	 * @param control
	 * @returns
	 */
	static validateDateAfterToday(control: AbstractControl): ValidationErrors | null {
		if (isControlEmpty(control)) {
			return null;
		}

		let error = null;
		const today = add(new Date(), { months: 0 });
		const split = control.value.split('-');
		const x = new Date(split[0], split[1] - 1, split[2]);

		if (isBefore(x, today)) {
			error = { dateActual: true };
		}

		return error;
	}

	/**
	 * Prooves that date is today's date or after
	 *
	 * @param control
	 * @returns
	 */
	static validateDateTodayOrAfter(control: AbstractControl): ValidationErrors | null {
		if (isControlEmpty(control)) {
			return null;
		}

		let error = null;
		const today = format(new Date(), 'yyyyMMdd');
		const userInput = format(parseISO(control.value.replace(/-/g, '')), 'yyyyMMdd');

		if (userInput < today) {
			error = { dateTodayOrAfter: true };
		}

		return error;
	}

	/**
	 * Prooves that date is before today
	 *
	 * @param control
	 * @returns
	 */
	static validateDateBeforeToday(control: AbstractControl): ValidationErrors | null {
		if (isControlEmpty(control)) {
			return null;
		}

		let error = null;
		const today = add(new Date(), { months: 0 });
		const split = control.value.split('-');
		const x = new Date(split[0], split[1] - 1, split[2]);

		if (isAfter(x, today)) {
			error = { noDateActual: true };
		}

		return error;
	}

	static validateDateTodayOrBefore(control: AbstractControl): ValidationErrors | null {
		if (isControlEmpty(control)) {
			return null;
		}

		let error = null;
		const today = format(new Date(), 'yyyyMMdd');
		const userInput = format(parseISO(control.value.replace(/-/g, '')), 'yyyyMMdd');

		if (userInput > today) {
			error = { dateTodayOrBefore: true };
		}

		return error;
	}

	static validateDateJanuaryPreviousYear(control: AbstractControl): ValidationErrors | null {
		if (isControlEmpty(control)) {
			return null;
		}

		let error = null;

		const split = control.value.split('-');
		const x = new Date(split[0], split[1] - 1, split[2]);
		const fechaLimite = new Date(new Date().getFullYear() - 1, 0, 1);

		if (x < fechaLimite) {
			error = { dateJanuaryPreviousYear: true };
		}
		return error;
	}

	static validateDateJanuaryPrevious2022(control: AbstractControl): ValidationErrors | null {
		if (isControlEmpty(control)) {
			return null;
		}

		let error = null;

		const split = control.value.split('-');
		const x = new Date(split[0], split[1] - 1, split[2]);
		const fechaLimite = new Date(2022, 0, 1);

		if (x < fechaLimite) {
			error = { dateJanuaryPreviousYear: true };
		}
		return error;
	}

	/**
	 * Prooves that date not is before a limit date
	 *
	 * @param control
	 * @returns
	 */
	static validateBeforeLimitYear(limit: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (isControlEmpty(control)) {
				return null;
			}

			let error = null;
			const today = add(new Date(), { years: -limit });
			const split = control.value.split('-');
			const x = new Date(split[0], split[1] - 1, split[2]);

			if (isAfter(today, x)) {
				if (limit === 150) {
					error = { noDateLimit150: true };
				}
				if (limit === 50) {
					error = { noDateLimit50: true };
				}
			}
			return error;
		};
	}
	/**
	 * Prooves that dateBefore is before a dateAfter
	 *
	 * @param dateBefore
	 * @returns
	 */
	static validateDateBeforeDate(dateBefore: AbstractControl, errorKey: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (isControlEmpty(control)) {
				return null;
			}

			let error = null;

			const split = control.value.split('-');
			const x = new Date(split[0], split[1] - 1, split[2]);
			const spl = dateBefore.value.split('-');
			const dBefore = new Date(spl[0], spl[1] - 1, spl[2]);

			if (isAfter(dBefore, x)) {
				error = { [errorKey]: true };
			}
			return error;
		};
	}
	static validateDateAfterDate(dateAfter: AbstractControl, errorKey: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (isControlEmpty(control)) {
				return null;
			}

			let error = null;

			const split = control.value.split('-');
			const x = new Date(split[0], split[1] - 1, split[2]);
			const spl = dateAfter.value.split('-');
			const dAfter = new Date(spl[0], spl[1] - 1, spl[2]);

			if (isAfter(x, dAfter)) {
				error = { [errorKey]: true };
			}
			return error;
		};
	}
	/**
	 * Validates backend expresions
	 *
	 * @param expresion
	 * @param flags
	 * @param error
	 * @returns
	 */
	static backendPattern(expresion: string | RegExp, flags: any, error: any): ValidatorFn {
		return (control: AbstractControl): { [err: string]: boolean | any } | null => {
			if (isControlEmpty(control)) {
				return null;
			}

			const regexp = new RegExp(expresion, flags);

			if (!regexp.test(control.value)) {
				return { backendValidationError: { errorMessage: error } };
			} else {
				return null;
			}
		};
	}

	/**
	 * Validates backend conditions
	 *
	 * @param condition
	 * @param error
	 * @param responses
	 * @param variables
	 * @returns
	 */
	static backendCondition(condition: string, error: any, responses: any, variables: any = {}): ValidationErrors | null {
		if (condition === '') {
			return null;
		}

		if (!CoreExpressions.evaluate(condition, responses, variables)) {
			return { backendValidationError: { errorMessage: error } };
		}

		return null;
	}

	/**
	 * Validates if a values is between min and max range
	 *
	 * @param min
	 * @param max
	 * @returns
	 */
	static validateRange(min?: number, max?: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (isControlEmpty(control)) {
				return null;
			}
			const isMax = max && !(isNaN(max) || max === null) && +control.value > max;
			const isMin = min && !(isNaN(min) || min === null) && +control.value < min;
			if (isMax || isMin) {
				return { minMax: { errorMessage: `Introduce un número entre ${min} y ${max}` } };
			}

			return null;
		};
	}

	/**
	 * Validates alphabetical values
	 *
	 * @param control
	 * @returns
	 */
	static validateAlphabetical(control: AbstractControl): ValidationErrors | null {
		if (isControlEmpty(control)) {
			return null;
		}

		const regexp = new RegExp(/^[a-zA-ZÀ-ÿÑñ\-]+( [a-zA-ZÀ-ÿÑñ\-]+)*$/, 'is');

		if (!regexp.test(control.value)) {
			return { alphabetical: true };
		} else {
			return null;
		}
	}

	/**
	 * Validates alphanumeric values
	 *
	 * @param control
	 * @returns
	 */
	static validateAlphanumeric(control: AbstractControl): ValidationErrors | null {
		if (isControlEmpty(control)) {
			return null;
		}

		const regexp = new RegExp(/^[a-zñ0-9]+( [a-zñ0-9]+)*$/, 'is');

		if (!regexp.test(control.value)) {
			return { pattern: true };
		} else {
			return null;
		}
	}

	/**
	 * Validates email control
	 *
	 * @param control
	 * @returns
	 */
	static validateEmail(control: AbstractControl): ValidationErrors | null {
		if (isControlEmpty(control)) {
			return null;
		}

		// eslint-disable-next-line max-len
		const regexp = new RegExp(/^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/, 'is');

		if (!regexp.test(control.value) || control.value.length < 10 || control.value.length > 60) {
			return { email: true };
		} else {
			return null;
		}
	}

	/**
	 * Validates csv control
	 *
	 * @param control
	 * @returns
	 */
	static validateCSV(control: AbstractControl): ValidationErrors | null {
		if (isControlEmpty(control)) {
			return null;
		}
		const regexp = new RegExp(/[a-z0-9]{8}/gi, 'is');
		if (!regexp.test(control.value)) {
			return { csv: true };
		} else {
			return null;
		}
	}

	/**
	 * Validates money control
	 *
	 * @param control
	 * @returns
	 */
	static validateMoney(control: AbstractControl): ValidationErrors | null {
		if (isControlEmpty(control)) {
			return null;
		}

		let error = null;
		const expDecimals = /^\d*(?:\,\d{1,2})?$/;

		if (!expDecimals.test(control.value)) {
			error = { numericMoney: true };
		}

		return error;
	}

	/**
	 * Validates phone control
	 *
	 * @param control
	 * @returns
	 */
	static validatePhone(control: AbstractControl): ValidationErrors | null {
		if (isControlEmpty(control)) {
			return null;
		}

		let error = null;
		const exp = /^(\d[ -]*){9}$/;

		if (!exp.test(control.value)) {
			error = { phone: true };
		}

		return error;
	}

	/**
	 * Validates IBAN control
	 *
	 * @param control
	 * @returns
	 */
	static validateIban(control: AbstractControl): ValidationErrors | null {
		if (isControlEmpty(control)) {
			return null;
		}

		const error = { iban: true };
		const iban = control.value.trim().toUpperCase().replace(/\s/g, '');
		const exp = /ES\d{22}$/;

		if (iban.length !== 24 || !exp.test(iban)) {
			return error;
		}

		// Se coge las primeras dos letras y se pasan a números
		const letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
		const letra1 = iban.substring(0, 1);
		const letra2 = iban.substring(1, 2);

		const num1 = letras.search(letra1) + 10;
		const num2 = letras.search(letra2) + 10;

		//Se sustituye las letras por números.
		let isbanaux = String(num1) + String(num2) + iban.substring(2);
		// Se mueve los 6 primeros caracteres al final de la cadena.
		isbanaux = isbanaux.substring(6) + isbanaux.substring(0, 6);

		//Se calcula el resto
		const parts = Math.ceil(isbanaux.length / 7);
		let remainer = '';

		for (let i = 1; i <= parts; i++) {
			remainer = String(parseFloat(remainer + isbanaux.substring((i - 1) * 7, 7)) % 97);
		}

		if (remainer === '1') {
			return null;
		}

		return error;
	}

	/**
	 * Validates file name lenght
	 *
	 * @param control
	 * @returns
	 */
	static validateFileNameLength(control: AbstractControl): ValidationErrors | null {
		if (isControlEmpty(control)) {
			return null;
		}

		if (control.value.name.length > 50) {
			return { dniFileName: true };
		} else {
			return null;
		}
	}

	/**
	 * Validates if control value is an array and if is not empty
	 *
	 * @param control
	 * @returns
	 */
	static requiredArray(control: AbstractControl): ValidationErrors | null {
		let error = null;
		const input = control.value;

		if (Array.isArray(input) && input.length === 0) {
			error = { requiredArray: true };
		}

		return error;
	}

	/**
	 * Not allow to spaces on control value
	 *
	 * @param control
	 * @returns
	 */
	static notAllowingSpaces(control: AbstractControl): ValidationErrors | null {
		let error = null;
		const exp = /^\S*$/;

		if (!exp.test(control.value)) {
			error = { notAllowingSpaces: true };
		}

		return error;
	}

	/**
	 * Validates whether the option chosen from a selector is within the list of options
	 *
	 * @param options
	 * @returns
	 */
	static validateItem(options: any[]): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			let error = null;
			let foundItem = null;
			const input = control.value;

			if (options.length > 0 && input) {
				foundItem = options.filter(option => String(option.value) === String(input));
				if (foundItem.length === 0) {
					error = { notMatch: true };
				}
			}

			return error;
		};
	}
}
