import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StepsComponent } from './steps.component';
import { SharedModule } from '../../shared.module';

@NgModule({
	declarations: [StepsComponent],
	exports: [StepsComponent],
	imports: [CommonModule, SharedModule, TranslateModule],
})
export class StepsModule {}
