import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { TokenService } from './token.service';

/**
 * Error interceptor injectable. Implements HTTP interceptor
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	/**
	 * Error interceptor constructor
	 *
	 * @param _injector injector
	 */
	constructor(private _injector: Injector) {}

	/**
	 * Handles the request
	 *
	 * @param req HTTP request
	 * @param next HTTP handle
	 * @returns next HTTP request handled pipe
	 */
	intercept(req: HttpRequest<any>, next: HttpHandler): any {
		return next.handle(req).pipe(
			catchError(err => {
				this.checkUnauthorized(err);
				return err;
			}),
		);
	}

	private checkUnauthorized = (err: HttpErrorResponse) => {
		if (err.status === 401) {
			this._injector.get(TokenService).setToken('');
			this._injector.get(Router).navigate(['/500'], { state: { code: '001' } });
		}
	};
}
