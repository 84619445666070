import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

/**
 * Cache interceptor injectable
 */
@Injectable()
export class CacheInterceptor implements HttpInterceptor {
	/**
	 * Empty constructor
	 */
	constructor() {
		// Empty constructor
	}

	/**
	 * Handles HTTP request
	 *
	 * @param req HTTP request
	 * @param next HTTP handle
	 * @returns next handle HTTP request
	 */
	intercept(req: HttpRequest<any>, next: HttpHandler): any {
		if (this.isBffCall(req.url)) {
			const clonedReq = req.clone({ headers: req.headers.set('ngsw-bypass', '') });
			return next.handle(clonedReq);
		}

		return next.handle(req);
	}

	/**
	 * Checks if the call is bff
	 *
	 * @param requestUrl request URL
	 * @returns true if the call is bff
	 */
	private isBffCall(requestUrl: string): boolean {
		return /\/bff\//.test(requestUrl);
	}
}
