import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * cache service
 */
@Injectable({
	providedIn: 'root',
})
export class CacheService {
	/**
	 * Cache map
	 */
	private _cache = new Map();

	/**
	 * Cache service constructor
	 *
	 * @param _httpClient
	 */
	constructor(private _httpClient: HttpClient) { }

	/**
	 * Sets cache data
	 *
	 * @param id string id
	 * @param value value data
	 */
	set(id: string, value: any): void {
		this._cache.set(id, value);
	}

	/**
	 * Returns data from cache by id
	 *
	 * @param id string id
	 * @returns id and value
	 */
	get(id: string) {
		return this._cache.get(id);
	}

	/**
	 * Checks if an id exists
	 *
	 * @param id string id
	 * @returns boolean true if exists
	 */
	has(id: string) {
		return this._cache.has(id);
	}

	/**
	 * Clears the cache
	 */
	clear(): void {
		this._cache.clear();
	}

	/**
	 * Returns data from cache for subsequent calls
	 *
	 * @param url URL for the GET request
	 * @param options Optional options in the request
	 * @returns Request response or cached data
	 */
	getWithCache(url: string, options?: any) {
		/**
		 * Cache URL request
		 */
		const cacheId = url;

		if (!this.has(cacheId)) {
			return this._httpClient.get(url, options).pipe(tap(result => this.set(cacheId, result)));
		}

		return of(this.get(cacheId));
	}
}
