import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { BehaviorSubject } from 'rxjs';
import { IAppConfig } from '../../models/core.model';
import { TagType } from './ContentType';
import { FeatureToggleService } from '../../services/feature-toggle/feature-toggle.service';
/**
 * Token service injectable
 */
@Injectable({ providedIn: 'root' })
export class GTMService extends GoogleTagManagerService {
	static QUESTIONARY = 'Cuestionario';
	static FORM = 'Formulario';
	static SPLITTER = '|';
	static TENANT = 'IMV';
	static IDENTIFICATION = 'Identificación';
	pageNameSubject: BehaviorSubject<string> = new BehaviorSubject('');
	appConfig!: IAppConfig;
	private get analyticPushTitle(): boolean {
		return this.ANALYTIC_PUSH_TITLE;
	}
	private ANALYTIC_PUSH_TITLE = false;

	get isCaiss() {
		return this.appConfig.caiss === 1;
	}
	get isCitizen() {
		return this.appConfig.caiss === 0;
	}

	init(config: IAppConfig, featureToggleService: FeatureToggleService) {
		this.appConfig = config;
		featureToggleService.loadFeatureToggle('ANALYTIC_PUSH_TITLE', this);
		if (this.isCitizen) {
			this.addGtmToDomSubscription();
			this.pageNameSubscription();
		}
	}

	private addGtmToDomSubscription() {
		this.addGtmToDom().then(
			res => console.log('GTM: is added GTM to DOM', res),
			err => console.log('GTM: is added GTM to DOM', err),
		);
	}

	private pageNameSubscription() {
		this.pageNameSubject.subscribe(pageName => {
			if (pageName !== '') {
				const tag: TagType = { content: { page_name: pageName } };
				document.title = pageName;
				if (this.analyticPushTitle) {
					this.pushTag(tag).then(
						() => tag,
						() => tag,
					);
				}
			}
		});
	}
}
