/* eslint-disable no-prototype-builtins */
import { QuestionType, Responses, TransformResponsesToDtoConfig } from '../../shared/models/shared.model';

/**
 * Class to receive/send form answers from/to the backend.
 */
export class CoreForm {
	/**
	 * Receives the user answers to the form (from a draft) and transforms that array to the object used in the frontend.
	 *
	 * @param responses The user responses saved in the DDBB.
	 * @returns A key-vaue object with the user responses to be used in the frontend.
	 */
	static transformResponsesFromDto(responses: any[]): { [key: string]: any } {
		const formResponses: any = {};

		for (const resp of responses) {
			const key = resp.key.replace(/\./g, '_');
			let value = resp.value;

			// Decode document values
			if (key.startsWith('RF') || value.startsWith('[') || (key.startsWith('PI') && value.startsWith('{'))) {
				try {
					value = JSON.parse(value);
				} catch (err) {
					value = [];
				}
			}

			formResponses[key] = value;
		}

		return formResponses;
	}

	/**
	 * Transforms the user responses in the frontend to the array the backend needs to process and store the data.
	 *
	 * @param responses A key-value object with all the user answers.
	 * @param config Configuration to specify the transformation behaviour.
	 * @returns An array the backend expects to receive to process and store those answers in the DDBB.
	 */
	static transformResponsesToDto(responses: any, config?: TransformResponsesToDtoConfig) {
		const data: Responses[] = [];

		const isfilterActive = config?.onlyInclude?.length;

		for (const fieldKey in responses) {
			if (responses[fieldKey]) {
				// Don't send empty strings
				const key: string = fieldKey.replace(/_/g, '.'); // Send children with . instead of _
				const regex = new RegExp(key + '\\.\\d'); // Find a children
				const keys = Object.keys(responses).map(i => i.replace(/_/g, '.'));
				const hasChildren = keys.some(id => regex.test(id));
				const qt: 'PC' | 'MI' | 'PI' | 'PF' | 'MO' = key.substring(0, 2) as 'PC' | 'MI' | 'PI' | 'PF' | 'MO';
				const isRF = (qt as string) === 'RF';
				const isPCIF = !isRF;
				if (isfilterActive && !config.onlyInclude?.includes(QuestionType[qt])) {
					continue;
				}
				if ((hasChildren && isRF) || (isPCIF && this.isUselessArray(responses, fieldKey))) {
					// Don't send RF parents or PC|PF|PI responses being an empty array or array full of strings
				} else if (hasChildren) {
					const item = { key, value: this.cleanBase64(responses[fieldKey]), tipo: '3', hasChildren: true };
					data.push(item);
				} else {
					const item = { key, value: this.cleanBase64(responses[fieldKey]), tipo: '3', hasChildren: false };
					data.push(item);
				}
			}
		}

		return data;
	}

	/**
	 * Used to filter answers the backend doesn't need. To save bandwidth.
	 *
	 * @param responses The user responses as a key-value object.
	 * @param key The key for the answers to be checked.
	 * @returns Whether or not this answers should be fileterd and not sent to the backend.
	 */
	static isUselessArray(responses: any, key: string) {
		return Array.isArray(responses[key]) && responses[key].every((item: string | any[]) => (typeof item === 'string' || item instanceof String) && item.length === 0);
	}

	// Remove fileBase64 content
	/**
	 * Removes the field fileBase64 used in the frontend but not necessary in the backend.
	 *
	 * @param response The user response to be cleaned.
	 * @returns The same response with the field fileBase64 as an empty string.
	 */
	static cleanBase64(response: any | any[]) {
		if (!Array.isArray(response)) {
			try {
				return response.hasOwnProperty('fileBase64') ? { ...response, fileBase64: '' } : response;
			} catch (error) {
				return response;
			}
		}

		return response.map(item => {
			if (!item.hasOwnProperty('fileBase64')) {
				return item;
			}

			return { ...item, fileBase64: '' };
		});
	}
}
