import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { GuardService } from '../services/guard/guard.service';

/**
 * Did not visit Videoidentification page Guard
 */
@Injectable()
export class DidNotVisitVideoidentificationGuard {
	/**
	 * Checks if the user has visited the videoidentification page
	 *
	 * @param _router Router
	 * @param _guardService Guard Service
	 */
	constructor(
		private _router: Router,
		private _guardService: GuardService,
	) {}

	/**
	 * Checks if the user can activate children
	 *
	 * @returns true if the user can active children
	 */
	canActivateChild(): boolean | UrlTree {
		return this._didNotVisitVideoidentification();
	}

	/**
	 * Checks if the user can activate an element or event
	 *
	 * @returns true if the user can active it
	 */
	canActivate(): boolean | UrlTree {
		return this._didNotVisitVideoidentification();
	}

	/**
	 * Checks if the user can load an element or event
	 *
	 * @returns true if the user can load it
	 */
	canLoad(): boolean | UrlTree {
		return this._didNotVisitVideoidentification();
	}

	/**
	 * Checks if the user has visited the videoidentification page
	 *
	 * @returns true if the user has visited the identification page
	 */
	private _didNotVisitVideoidentification(): boolean | UrlTree {
		if (this._guardService.canAccessIdentification()) {
			return true;
		} else {
			return this._router.parseUrl('/home');
		}
	}
}
