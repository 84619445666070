<imv-ui-modal *ngIf="i18n" #modal [isMobile]="isMobile" type="fail">
	<imv-ui-button eid="dialog-close" theme="secondary" variant=" mdc-button--secondary mdc-button--modal" (click)="close()">
		<span slot="text">{{ action }}</span>
	</imv-ui-button>
	<h1 slot="title" id="dialog-title">{{ title }}</h1>
	<p slot="text" id="dialog-description">{{ description }}</p>
	<div slot="actions">
		<imv-ui-button eid="dialog-accept" theme="secondary" variant=" mdc-button--secondary mdc-button--modal" (click)="close()">
			<span slot="text">{{ action }}</span>
		</imv-ui-button>
	</div>
</imv-ui-modal>
