/**
 * App Store Interface Structure
 */
export interface AppStore {
	[key: string]: any;
}

/**
 * Identifiers for different types of questions/answers in the communication with the backend.
 */
export enum QuestionType {
	PC,
	MI,
	PI,
	PF,
	MO,
}

/**
 * Navigation bottom bar configuration structure
 */
export interface Navigation {
	/**
	 * Start button visibility
	 */
	isBegin: boolean;

	/**
	 * isNext variable determinates if the button next is displayed
	 */
	isNext: boolean;

	/**
	 * isDisabledNext variable determinates if the button next is disabled (true) or visible (false)
	 */
	isDisabledNext: boolean;

	/**
	 * isLoadingNext variable changes the button next text to Loading, the button is disabled and we add to the button a loader
	 */
	isLoadingNext: boolean;

	/**
	 * isDisabledBegin variable determinates if the button begin is disabled (true) or visible (false)
	 */
	isDisabledBegin: boolean;

	/**
	 * isDisableModal variable determinates if the modal is disabled (true) or visible (false)
	 */
	isDisabledModal?: boolean;

	/**
	 * isPrevious variable determinates if the button previous is displayed
	 */
	isPrevious: boolean;

	/**
	 * isCancel variable determinates if the button cancel is displayed
	 */
	isCancel: boolean;

	/**
	 * Optional: isSubmit variable determinates if the button submit is displayed
	 */
	isSubmit?: boolean;
}

/**
 * Navigation configuration
 */
export interface NavigationConfig extends Partial<Navigation> { } // eslint-disable-line @typescript-eslint/no-empty-interface

/**
 * Question list array
 */
export type QuestionList = Array<Question>;

/**
 * Question call structure
 */
export interface Question {
	/**
	 * Id of the question
	 */
	idPregunta: string;

	/**
	 * Variable code
	 */
	codigoVariable: string;

	/**
	 * Name of the question
	 */
	nombre: string;

	/**
	 * Description of the question
	 */
	descripcion: string;

	/**
	 * Type of the response expected
	 */
	tipoRespuesta: number;

	/**
	 * Id of the section where the question will be displayed
	 */
	idSeccion: number;

	/**
	 * Order of the question in the form structure
	 */
	orden: number;

	/**
	 * Key list
	 */
	keyLista: QuestionKeyItem[];

	/**
	 * Minimum number of characters permitted in the question
	 */
	longitudMin: number;

	/**
	 * Maximum number of characters permitted in the question
	 */
	longitudMax: number;

	/**
	 * Default value of the question
	 */
	valorPorDefecto: string;

	/**
	 * Active question boolean
	 */
	activa: number;

	/**
	 * Determinates if the question is mandatory
	 */
	obligatoria: boolean;

	/**
	 * Id of the form where the question will be displayed
	 */
	idFormulario: number;

	/**
	 * Defines the condition to display the question
	 */
	expresionVisibilidad: string;

	/**
	 * Amount array
	 */
	importes?: QuestionImporteItem[];
}

/**
 * Question Key Item structure
 */
export interface QuestionKeyItem {
	/**
	 * Key
	 */
	key: string;

	/**
	 * Option
	 */
	opcion: string;

	/**
	 * Value of the question key item
	 */
	valor: string;
}

/**
 * Question item of type amount structure configuration
 */
export interface QuestionImporteItem {
	/**
	 * Optional: Minimum amount supported in the question
	 */
	min?: string;

	/**
	 * Optional: Maximum amount supported in the question
	 */
	max?: string;

	/**
	 * Optional: Condition expected to display the question amount item
	 */
	expresionVisibilidad?: string;

	/**
	 * Notes of the question amount item (dudas)
	 */
	notas: string;
}

/**
 * Response structure expected from the question call
 */
export interface QuestionResponse {
	/**
	 * Key of the question
	 */
	key: string;

	/**
	 * Value response of the question
	 */
	valor: string;
}
/**
 * Validate email call structure
 */
export interface ValidateEmail {
	/**
	 * Identity document number of the suspect to validate
	 */
	numeroDocumento: string;

	/**
	 * Complete name of the suspect to validate
	 */
	nombreCompleto: string;

	/**
	 * Email of the suspect to validate
	 */
	correoElectronico: string;
}

/**
 * Validation of identity structure
 */
export interface ValidateIdentity {
	/**
	 * Type of the identity document to validate
	 */
	tipoDocumento: number;

	/**
	 * Number of the identity document to validate
	 */
	numeroDocumento: string;
}

/**
 * Response of the validate email structure
 */
export interface ValidateEmailResponse {
	/**
	 * Value that determinates if the email is valid or not
	 */
	confirmado: boolean;

	/**
	 * Email that was validated
	 */
	correoElectronico: string;

	/**
	 * Expire date of the validation
	 */
	fechaExpiracion: string;

	/**
	 * Complete name of the suspect that was validated
	 */
	nombreCompleto: string;

	/**
	 * Identification document number of the suspect that was validated
	 */
	numeroDocumento: string;
}

/**
 * Step structure
 */
export interface Step {
	/**
	 * Text of the step
	 */
	text: string;

	/**
	 * Id of the step
	 */
	id: number;

	/**
	 * Defines if the Step is active (true) or not (false)
	 */
	isActive: boolean;

	/**
	 * Defines if the Step is completed (true) or not (false)
	 */
	isCompleted: boolean;
}
/**
 * Recovery token structure
 */
export interface RecoveryToken {
	/**
	 * Identification number to recover the token
	 */
	numDocumento: string;

	/**
	 * Email of the token to recover
	 */
	email: string;
}

/**
 * Draft token email for CAISS structure
 */
export type EmailCaiss = RecoveryToken;

/**
 * Response of the recovery token structure
 */
export interface RecoveryTokenResponse {
	/**
	 * Recovered token
	 */
	token: string;
}

/**
 * Recovery request structure
 */
export interface RecoveryRequest {
	/**
	 * Identification number to request the recovery of the token
	 */
	numDocumento: string;

	/**
	 * Token to request the recovery of the token
	 */
	token: string;

	/**
	 * Language request the recovery of the token
	 */
	lenguaje: string;

	/**
	 * Optional: email to recover request
	 */
	email?: string;
}

/**
 * Response of the recovery request structure
 */
export interface RecoveryRequestResponse {
	/**
	 * Recovered request token
	 */
	token: string;
}

/**
 * Response of the expedient structure
 */
export interface ExpedientResponse {
	/**
	 * IMV claim amount
	 */
	calculoIMV: string;

	/**
	 * Doubling code
	 */
	cdDesdoblamiento: string;

	/**
	 * Duplicity code
	 */
	cdDuplicidad: string;

	/**
	 * Managing entity code
	 */
	cdEntidadGestora: string;

	/**
	 * Cooficial language code
	 */
	cdLenguaCooficial: string;

	/**
	 * Family protection code
	 */
	cdProteccionFamiliar: string;

	/**
	 * Opening province code
	 */
	cdProvinciaApertura: string;

	/**
	 * Asigned province code
	 */
	cdProvinciaAsignada: string;

	/**
	 * Managing unit code
	 */
	cdUnidadGestion: string;

	/**
	 * Help key
	 */
	clavePrestacion: string;

	/**
	 * Communications
	 */
	comunicaciones: any;

	/**
	 * Total amount
	 */
	cuantiaTotal: any;

	/**
	 * Transitory economic data
	 */
	datosEconomicosTransitoria: any[];

	/**
	 * Expired economic data
	 */
	datosEconomicosVencidos: any[];

	/**
	 * Refusals array
	 */
	denegaciones: any[];

	/**
	 * In time previous claim boolean
	 */
	dentroPlazoReclamacionPrevia: boolean;

	/**
	 * Documents to subsanate
	 */
	documentosASubsanar: any[];

	/**
	 * Home
	 */
	domicilio: any;

	/**
	 * Bank acount
	 */
	dsIbanBancario: string;

	/**
	 * Opening year date
	 */
	fecAnyoApertura: string;

	/**
	 * Asignment date
	 */
	fecAsignacion: string;

	/**
	 * INOT comunication date
	 */
	fecComunicacionInot: string;

	/**
	 * Creation date
	 */
	fecCreacion: string;

	/**
	 * Previous claim date
	 */
	fecReclamacionPrevia: string;

	/**
	 * Registry date
	 */
	fecRegistro: string;

	/**
	 * Resolution date
	 */
	fecResolucion: string;

	/**
	 * Claim date
	 */
	fecSolicitud: string;

	/**
	 * Expedient id
	 */
	idExpedienteFuncional: string;

	/**
	 * Expedient id
	 */
	idExpedienteSeq: string;

	/**
	 * Registry id
	 */
	idRegistro: string;

	/**
	 * SGDA id
	 */
	idSgda: string;

	/**
	 * Claim id
	 */
	idSolicitud: string;

	/**
	 * Processor id
	 */
	idTramitador: string;

	/**
	 * Casuistry index
	 */
	indCasuistica: string;

	/**
	 * Extemporaneous index
	 */
	indExtemporanea: string;

	/**
	 * Citizen manager index
	 */
	indGestorCiudadano: string;

	/**
	 * Single parenthood boolean
	 */
	indMonoparentalidad: boolean;

	/**
	 * Disability boolean
	 */
	indDiscapacidad: boolean;

	/**
	 * Substate index
	 */
	indSubEstado: string;

	/**
	 * Supplemental income
	 */
	ingresosComplementarios: any[];

	/**
	 * Manual income
	 */
	ingresosManual: any[];

	/**
	 * Member array
	 */
	miembros: any[];

	/**
	 * Reductions
	 */
	minoraciones: any[];

	/**
	 * Name
	 */
	nombre: string;

	/**
	 * Citizen name
	 */
	nombreCiudadano: string;

	/**
	 * Notes array
	 */
	notas: any[];

	/**
	 * People UC number
	 */
	numPersonasUc: string;

	/**
	 * Previous claim posibily boolean
	 */
	posibilidadReclamacionPrevia: boolean;

	/**
	 * First surname
	 */
	primerApellido: string;

	/**
	 * Is representative boolean
	 */
	representante: boolean;

	/**
	 * Response array
	 */
	respuestas: any[];

	/**
	 * Second surname
	 */
	segundoApellido: string;

	/**
	 * Solicitant
	 */
	solicitante: any;

	/**
	 * Inadmissibility type
	 */
	tipoInadmision: any;

	/**
	 * Verifications
	 */
	verificaciones: any[];

	/**
	 * SGDA version
	 */
	versionSgda: string;

	/**
	 * Form version
	 */
	versionFormulario?: string;
}

/**
 * Responses structure
 */
export interface Responses {
	/**
	 * Id of the response
	 */
	key: string;

	/**
	 * Value of the response
	 */
	value: any;

	/**
	 * Type of the response
	 */
	tipo: string;

	/**
	 * Determinates if the response have children (true) or not (false)
	 */
	hasChildren: boolean;
}

/**
 * Responses structure of the steps
 */
export interface StepResponses {
	/**
	 * Version of the step
	 */
	version: string;

	/**
	 * Order of the step
	 */
	ordenPaso: number;

	/**
	 * Optional: Determinates if the step will be shown in Caiss mode (true) or not (false)
	 */
	isCaiss?: boolean;

	/**
	 * Optional: Determinates if the step will be shown in Admin mode (true) or not (false)
	 */
	isAdmin?: boolean;

	/**
	 * Responses of the step questions
	 */
	respuestas: Responses[];
}

/**
 * Validation error structure
 */
export interface ValidationError {
	/**
	 * Error message of the validation
	 */
	errorMessage: string;

	/**
	 * Determinates if the validation service is down (true) or not (false)
	 */
	serviceDown: boolean;
}

/**
 * Photo structure
 */
export interface Photo {
	/**
	 * Id of the photo
	 */
	id: string;

	/**
	 * Name of the photo
	 */
	name: string;

	/**
	 * Type of the photo
	 */
	type: string;

	/**
	 * Size of the photo
	 */
	size: number;

	/**
	 * Hash of the photo file
	 */
	file: any;

	/**
	 * Hash of the photo file in base64
	 */
	fileBase64: any;
}

/**
 * Verify captcha structure
 */
export interface VerifyCaptcha {
	/**
	 * Id of the verify captcha
	 */
	id: string;

	/**
	 * Captcha token that will be verified
	 */
	captchaToken: string;
}

/**
 * Responses from verify captcha validation
 */
export interface VerifyCaptchaResponse {
	/**
	 * Determinates if the verification of the captcha is ok (true) or not (false)
	 */
	'success': boolean;

	/**
	 * Timestamp of the challenge load
	 */
	'challenge_ts'?: string;

	/**
	 * Optional: Hostname of the verify captcha
	 */
	'hostname'?: string;

	/**
	 * Optional: Error codes if there is errors
	 */
	'error-codes'?: { [key: string]: string }[];
}

/**
 * Form configuration structure
 */
export interface FormConfig {
	/**
	 * Version of the form
	 */
	version: string;
}

/**
 * Trace structure
 */
export interface Trace {
	/**
	 * Code of the question where the error happened
	 */
	codigoPregunta: string;

	/**
	 * Message of the trace
	 */
	mensaje: string;
}

/**
 * Responses used in prevalidation
 */
export interface PrevalidationResponse {
	/**
	 * Id of the prevalidation
	 */
	id: string;

	/**
	 * Defines if the prevalidation has an error (true) or not (false)
	 */
	hasError: boolean;

	/**
	 * Defines the step of the validation in the prevalidation
	 */
	validationStep: number;

	/**
	 * Optional: Error of the prevalidation, if there is not an error, this parameter will not exists
	 */
	error?: {
		/**
		 * Error code of the prevalidation
		 */
		errorCode: string;

		/**
		 * Traces of the error in the prevalidation
		 */
		traces: Trace[];
	};
}

/**
 * Configuration object to control de tranformation behaviour.
 */
export interface TransformResponsesToDtoConfig {
	/**
	 * The types to be included in the output. If not set, it outputs all types.
	 */
	onlyInclude?: QuestionType[];
}

/**
 * Responses used in form step by front
 */
export interface UserResponses {
	[key: string]: any;
}

/**
 * Steps configuration
 */
export interface StepsConfig extends Partial<Step> { } // eslint-disable-line @typescript-eslint/no-empty-interface
