import { Component, Input } from '@angular/core';

/**
 * IMV form error
 */
@Component({
	selector: 'imv-form-error',
	template: '',
})
export class FormErrorComponent {
	/**
	 * Error code
	 */
	@Input()
	code!: string;
	/**
	 * Error message
	 */
	@Input() message!: string;
}
