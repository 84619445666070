import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogsComponent } from './dialogs.component';
import { UiComponentsModule } from '../ui-components/public-api';
import { DialogsInterceptor } from './dialogs.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DialogWithControlComponent } from './dialog-with-control/dialog-with-control.component';
import { FormControlModule } from '../components/form-control/form-control.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { DialogComponent } from './dialog/dialog.component';

const DIALOG_COMPONENTS = [DialogsComponent, DialogComponent, DialogConfirmComponent, DialogWithControlComponent];

@NgModule({
	declarations: [...DIALOG_COMPONENTS],
	imports: [UiComponentsModule, FormControlModule, ReactiveFormsModule, FormsModule, CommonModule],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: DialogsInterceptor,
			multi: true,
			deps: [Injector],
		},
	],
	exports: [...DIALOG_COMPONENTS],
})
export class DialogsModule {}
