import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfigManager } from '../../config/config';
import { APP_CONFIG } from '../../models/core.model';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaFormsModule, RecaptchaModule, RecaptchaV3Module } from 'ng-recaptcha';

@NgModule({
	declarations: [],
	imports: [CommonModule, RecaptchaV3Module, RecaptchaModule, RecaptchaFormsModule],
	providers: [
		{ provide: APP_CONFIG, useClass: ConfigManager },
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useFactory: (_injector: Injector) => _injector.get(APP_CONFIG).config.captcha,
			deps: [Injector],
		},
	],
})
export class CaptchaModule {}
