/**
 * Class used to deal with documents.
 */
export class CoreUpload {
	/**
	 * Emulates a click on a temporal link to download a document automatically.
	 *
	 * @param contentBase64 The base64 docuemnt's content.
	 * @param mimeType The document's mime type.
	 * @param fileName The document's name.
	 */
	static download(contentBase64: string, mimeType: string, fileName: string) {
		const link = document.createElement('a');
		link.href = `data:${mimeType};base64,` + contentBase64;
		link.download = fileName;
		link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

		setTimeout(() => {
			link.remove();
		}, 100);
	}

	/**
	 * Transforms the base64 content of a document to the binary form.
	 * Is mainly used to send documents in a FormData.
	 *
	 * @param dataURI The document's base64 content.
	 * @returns The document in binary form.
	 */
	static dataURIToBlob(dataURI: string) {
		const splitDataURI = dataURI.split(',');
		const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
		const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

		const ia = new Uint8Array(byteString.length);

		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new Blob([ia], { type: mimeString });
	}
}
