/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Idle-timer shared class
 */
@Injectable()
export default class IdleTimer {
	/**
	 * Timeout
	 */
	timeout: any;

	/**
	 * On timeout
	 */
	onTimeout: any;

	/**
	 * Event handler
	 */
	eventHandler: any;

	/**
	 * Interval
	 */
	interval: any;

	/**
	 * Timeout tracker
	 */
	timeoutTracker: any;

	/**
	 * Idle-timer constructor. Sets the timeout, handles the expired time update. Tracks the mouse events and starts the interval.
	 *
	 * @param _router router
	 */
	constructor(private _router: Router) {
		if (localStorage.getItem('_expiredTime')) {
			localStorage.removeItem('_expiredTime');
		}
		this.timeout = 600;
		this.onTimeout = () => {};

		this.eventHandler = this.updateExpiredTime.bind(this);
		this.tracker();
		this.startInterval();
	}

	/**
	 * Starts the interval
	 */
	startInterval() {
		this.updateExpiredTime();
		this.interval = setInterval(() => {
			const cachedExpiredTime = localStorage.getItem('_expiredTime');
			if (cachedExpiredTime) {
				const expiredTime = parseInt(cachedExpiredTime, 10);
				if (expiredTime < Date.now() && this.onTimeout) {
					this.onTimeout();
					this.cleanUp();
				}
			}
		}, 1000);
	}

	/**
	 * Updates the expired time
	 */
	updateExpiredTime() {
		if (this.timeoutTracker) {
			clearTimeout(this.timeoutTracker);
		}
		this.timeoutTracker = setTimeout(() => {
			localStorage.setItem('_expiredTime', (Date.now() + this.timeout * 1000).toString());
		}, 300);
	}

	/**
	 * Tracks mouse events
	 */
	tracker() {
		window.addEventListener('mousemove', this.eventHandler);
		window.addEventListener('scroll', this.eventHandler);
		window.addEventListener('keydown', this.eventHandler);
	}

	/**
	 * Cleans the events up and redirects to the home page
	 */
	cleanUp() {
		clearInterval(this.interval);
		window.removeEventListener('mousemove', this.eventHandler);
		window.removeEventListener('scroll', this.eventHandler);
		window.removeEventListener('keydown', this.eventHandler);
		this._router.navigateByUrl('/home');
	}
}
