import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EI18nScope, I18N_SCOPE, I18N_FILE, APP_CONFIG } from '../../models/core.model';
import { ConfigManager } from '../../config/config';

/**
 * i18n Service
 */
@Injectable()
export class I18nService implements TranslateLoader {
	/**
	 * Configuration manager
	 */
	private readonly _configManager: ConfigManager;

	/**
	 * HTTP client
	 */
	private readonly _httpClient: HttpClient;

	/**
	 * i18n scope
	 */
	private readonly _i18nScope: EI18nScope;

	/**
	 * File name
	 */
	private readonly _fileName: string;

	/**
	 * i18n Service constructor
	 *
	 * @param _injector injector
	 */
	constructor(private _injector: Injector) {
		this._configManager = _injector.get<ConfigManager>(APP_CONFIG);
		this._i18nScope = _injector.get<EI18nScope>(I18N_SCOPE);
		this._fileName = _injector.get<string>(I18N_FILE);
		this._httpClient = _injector.get(HttpClient);
	}

	/**
	 * Gets the translation
	 *
	 * @param lang language
	 * @returns translated text
	 */
	getTranslation(lang: string): Observable<Record<string, unknown>> {
		const url = this._getScope(lang) + this._getFileName();
		return this._httpClient.get<Record<string, unknown>>(url);
	}

	/**
	 * Private. Gets the scope
	 *
	 * @param lang language
	 * @returns scope
	 */
	_getScope(lang: string): string {
		let scope = this._configManager.config?.i18n?.scope[this._i18nScope] || '';
		scope = scope.replace(/(^\/)|(\/$)/g, '');
		scope = scope.replace(/(\${lang})/g, lang);
		return `${scope}/`;
	}

	/**
	 * Gets the file name
	 *
	 * @returns file name
	 */
	_getFileName(): string {
		let name = this._fileName.replace(/^\//, '');
		name = name.replace(/.json$/, '');
		return `${name}.json`;
	}
}
