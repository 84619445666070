export const DIALOG_ERROR_CODES = [
	'BUSI_001',
	'BUSI_002',
	'BUSI_003',
	'BUSI_004',
	'BUSI_005',
	'BUSI_006',
	'BUSI_007',
	'BUSI_008',
	'BUSI_009',
	'BUSI_010',
	'BUSI_011',
	'BUSI_012',
	'BUSI_013',
	'BUSI_014',
	'BUSI_015',
	'BUSI_016',
	'BUSI_017',
	'BUSI_018',
	'BUSI_019',
	'BUSI_020',
	'BUSI_021',
	'BUSI_022',
	'BUSI_023',
	'BUSI_024',
	'BUSI_025',
	'BUSI_026',
	'BUSI_027',
	'BUSI_028',
	'BUSI_029',
	'BUSI_030',
	'BUSI_031',
	'BUSI_032',
	'BUSI_033',
	'BUSI_034',
	'BUSI_035',
	'BUSI_036',
	'BUSI_037',
	'BUSI_038',
	'BUSI_039',
	'BUSI_040',
	'BUSI_041',
	'BUSI_042',
	'BUSI_043',
	'BUSI_044',
	'BUSI_045',
	'BUSI_046',
	'BUSI_047',
	'BUSI_048',
	'BUSI_049',
	'BUSI_050',
	'BUSI_051',
	'BUSI_052',
	'BUSI_053',
	'BUSI_054',
	'BUSI_055',
	'BUSI_056',
	'BUSI_057',
	'BUSI_058',
	'BUSI_059',
	'BUSI_060',
	'BUSI_061',
	'BUSI_062',
	'BUSI_063',
	'ERROR_LOGO',
	'ERROR_CEA',
	'NO_PERMITIR_ADJUNTAR_DOCUMENTOS',
	'ERROR_MSC_TP_SUBESTADO_EXPEDIENTE',
	'ERROR_MSC_TP_CONFIGURACION',
	'ERROR_MSC_GEFIR',
	'INVALID_TOKEN_001',
	'INVALID_TOKEN_002',
	'INVALID_TOKEN_004',
	'INVALID_TOKEN_005',
	'BUSI_CIUDADANO_ALTA',
	'BUSI_CIUDADANO_BUSQUEDA',
	'EMPTY_RESULT',
	'JWT_ERROR_MAIL',
	'JWT_ERROR_DNI',
	'JWT_ERROR_ERROR',
	'JWT_ERROR_TOKEN_NO_SOPORTADO',
	// 'JWT_MATCH',
	'JWT_MATCH_PREFERRED',
	'INVALID_ARGUMENTS',
	'ERROR_REGISTRO',
	'ERROR_REGISTRO_RP',
	'ERROR_SGDA',
	'ERROR_SGDA_RP',
	'ERROR_FECHA_REGISTRO',
	'ENV_ENDPOINT_INVALID',
	'ERROR_CONEXION_APPIAN_VALIDACION',
	'ERROR_SAVE_DUPLICIDADES',
	'ERROR_DUPLICIDADES_IMV',
	'ERROR_DUPLICIDADES_PF',
	'ERROR_NOTIFICACION_TRAMITADOR_INOT',
	'NO_EXISTE_RELACION_SGDA_EXPEDIENTE_DOCUMENTO',
	'ERROR_GENERAR_EVIDENCIA_AEAT',
	'ERROR_GENERAR_PROCESAR_AEAT',
	'ERROR_GENERAR_EVIDENCIA_INE',
	'ERROR_GENERAR_PROCESAR_INE',
	'ERROR_POST_INE',
	'ERROR_GUARDAR_EXPEDIENTE_REINTENTOS',
	'ERROR_FICHERO_MALWARE',
	'ERROR_GUARDAR_EXPEDIENTE',
	'ERROR_SUBSANACION_SIN_FIRMA',
	'ERROR_GUARDAR_SUBSANACION_SIN_FIRMA',
	'ERROR_IMPORTES_INADMISION',
	'ERROR_NOTIFICACION_NO_EXISTENTE',
	'ERROR_TIPO_INADMISION',
	'ERROR_ADJUNTAR_DOCUMENTO',
	'SECURITY_CONFIG_INVALID',
	'ERROR_VALIDAR_ANTIVIRUS',
	'ERROR_FECHA_RECLAMACION',
	'ERROR_FECHA_EXPEDIENTE_INICIO',
	'ERROR_FECHA_EXPEDIENTE_FIN',
	'ERROR_FECHA_FORMATO',
	'ERROR_ESTADOS_ACTUALES',
	'ERROR_ESTADOS_FUNCIONALES',
	'ERROR_CUADOR_MANDO_ESTADOS_ACTUAL_HIOTORICO_BOLIGATORIO',
	'INTEG_APPIAN_001',
	'ERROR_EXPEDIENTE_SOLICITUD',
	'ERROR_VALIDACION',
	'ERROR_ALTA_PREGUNTA_SOLICITUD',
	'ERROR_TSD',
	'ERROR_TSD_TIMEOUT',
	'ERROR_TIPOLOGIA_DOCUMENTAL',
	'ERROR_NO_SOLICITANTE',
	'ERROR_MISMO_IPF',
	'ERROR_EMAIL_INEXISTENTE',
];
