import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigManager } from '../../config/config';
import { RequestGiss, APP_CONFIG } from '../../models/core.model';
import { JwtDecodeModule } from '../../modules/jwt-decode/jwt-decode.module';
const { jwtDecode } = JwtDecodeModule;

import { TokenService } from './token.service';
import { firstValueFrom } from 'rxjs';

/**
 * Auth service
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
	/**
	 * Token data instance
	 */
	private _tokenData: any = null;

	/**
	 * Gets the token, sets the service and extracts data from the JWT
	 *
	 * @param _http HTTP client
	 * @param configManager configuration manager
	 * @param tokenService token service
	 */
	constructor(
		private _http: HttpClient,
		@Inject(APP_CONFIG) private configManager: ConfigManager,
		private tokenService: TokenService,
	) {
		if (sessionStorage.getItem('token')) {
			const token = sessionStorage.getItem('token');
			if (token) {
				this.tokenService.setToken(token);
				this.extractDataFormJwt(token);
			}
		}
	}

	/**
	 * Checks if the token service has a token
	 *
	 * @returns true if the token service has a token
	 */
	public hasToken() {
		return !!this.tokenService.getToken();
	}

	/**
	 * Sets the token to the token service and session storage. Then, extracts data from the JWT
	 *
	 * @param token token string
	 */
	public setToken(token: string) {
		this.tokenService.setToken(token);
		sessionStorage.setItem('token', token);
		this.extractDataFormJwt(token);
	}

	/**
	 * Gets the token data
	 *
	 * @returns token data
	 */
	public getTokenData() {
		return this._tokenData;
	}

	/**
	 * Redirects external login
	 */
	public async redirectExternalLogin() {
		/**
		 * SAML data
		 */
		const samlData: RequestGiss | undefined = await firstValueFrom(this.getSamlRequest(this.configManager.config.caiss === 0));

		const form = document.createElement('form');
		form.action = this.configManager.config.apiHost.login;
		form.method = 'POST';
		if (samlData) {
			const relayState = document.createElement('input');
			relayState.name = 'RelayState';
			relayState.value = samlData.RelayState;
			relayState.type = 'hidden';
			form.appendChild(relayState);
			/**
			 * SAML request
			 */
			const samlRequest = document.createElement('input');
			samlRequest.name = 'SAMLRequest';
			samlRequest.value = samlData.SAMLRequest;
			samlRequest.type = 'hidden';
			form.appendChild(samlRequest);

			document.body.appendChild(form);
			form.submit();
		}
	}

	/**
	 * Gets SAML request
	 *
	 * @param isInternet is internet boolean
	 * @returns request GISS
	 */
	private getSamlRequest(isInternet: boolean) {
		/**
		 * URL for the request
		 */
		const url = this.configManager.config.apiHost.bff + `pasarelagiss/samlgiss?tenant=` + (isInternet ? 'imv-internet' : 'imv-intranet');

		return this._http.get<RequestGiss>(url);
	}

	/**
	 * Extracts data from the JWT
	 *
	 * @param token token string
	 */
	private extractDataFormJwt(token: string) {
		/**
		 * Sets token data to null
		 */
		this._tokenData = null;
		try {
			this._tokenData = jwtDecode(token);
		} catch (e) {
			console.log(e);
		}
	}
}
