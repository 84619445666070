import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { selectFormResponses } from '@shared/store';
import { APP_CONFIG, IAppConfig } from '../models/core.model';
import { ConfigManager } from '../config/config';

/**
 * Has answers Guard
 */

/**
 * Replaces union type with type alias
 */
type booleanUrlTree = boolean | UrlTree;

@Injectable()
export class HasAnswersGuard {
	/**
	 * Indicates if the user is CAISS
	 */
	private readonly _isCaiss: any;

	/**
	 * Injects application configuration and sets the isCaiss boolean to 1
	 *
	 * @param _store Store
	 * @param _router Router
	 * @param _injector Injector
	 */
	constructor(
		private _store: Store,
		private _router: Router,
		_injector: Injector,
	) {
		const appConfig: IAppConfig = _injector.get<ConfigManager>(APP_CONFIG).config;
		this._isCaiss = appConfig.caiss === 1;
	}

	/**
	 * Checks if the user can activate children
	 *
	 * @param childRoute child route
	 * @param state router state
	 * @returns true if the form has responses
	 */
	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<booleanUrlTree> | booleanUrlTree {
		return this._hasFormResponses(state.url);
	}

	/**
	 * Checks if the user can activate an element or event
	 *
	 * @param childRoute child route
	 * @param state router state
	 * @returns true if the form has responses
	 */
	canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<booleanUrlTree> | booleanUrlTree {
		return this._hasFormResponses(state.url);
	}

	/**
	 * Checks if the user can load an element or event
	 *
	 * @param route Route
	 * @param segments URL segments
	 * @returns true if the form has responses
	 */
	canLoad(route: Route, segments: UrlSegment[]): Observable<booleanUrlTree> | booleanUrlTree {
		const url = segments.map(s => s.path).join('/');
		return this._hasFormResponses(url);
	}

	/**
	 * Checks if the form has responses
	 *
	 * @returns true if the form has responses
	 */
	private _hasFormResponses(url = ''): Observable<booleanUrlTree> | booleanUrlTree | boolean {
		if (this._isCaiss && (url === 'step/1' || url === '/form/step/1')) {
			return true;
		} else {
			return this._store.select(selectFormResponses as any).pipe(
				map((formResponses: any) => {
					if (Object.keys(formResponses).length > 0) {
						return true;
					} else {
						return this._router.parseUrl('/home');
					}
				}),
			);
		}
	}
}
