import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { add, format, isAfter, isBefore, isMatch, isValid, parseISO, differenceInYears } from 'date-fns';

@NgModule({
	declarations: [],
	imports: [CommonModule],
})
export class DateModule {
	static add = add;
	static format = format;
	static isAfter = isAfter;
	static isBefore = isBefore;
	static isMatch = isMatch;
	static isValid = isValid;
	static parseISO = parseISO;
	static differenceInYears = differenceInYears;
}
