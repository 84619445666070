import { Lists } from '@shared/models';
import { DateModule } from '@app/core';
const { format, isMatch, parseISO, differenceInYears } = DateModule;

declare global {
	// eslint-disable-next-line id-blacklist

	/**
	 *  Extension of the String Interface
	 */
	interface String {
		/**
		 * Returns true if the array you pass to it by this parameter contains the string of the this variable.
		 *
		 * @param args
		 * @returns boolean
		 */
		contains(...args: Array<string>): boolean;

		/**
		 * Returns true if the array you pass to it by this parameter not contains the string of the this variable.
		 *
		 * @param args
		 * @returns
		 */
		notcontains(...args: Array<string>): boolean;

		/**
		 * Returns true if it is Spain
		 *
		 * @returns
		 */
		esEspanya(): boolean;

		/**
		 * At least one element of array is not Spain
		 *
		 * @returns
		 */
		noEspanya(): boolean;

		/**
		 * At least one element of array is in Europe
		 *
		 * @returns
		 */
		esEuropa(): boolean;

		/**
		 * At least one element of array is not in Europe
		 *
		 * @returns
		 */
		noEuropa(): boolean;

		/**
		 * Alias to lessDateThanToday
		 *
		 * @returns
		 */
		menorHoy(): boolean;

		/**
		 * Alias to edadMenor
		 *
		 * @returns
		 */
		menorEdad(edad: number): boolean;

		/**
		 * Alias to edadMayor
		 *
		 * @returns
		 */
		mayorEdad(edad: number): boolean;

		/**
		 * Alias to lessDateThan
		 *
		 * @returns
		 */
		edadMenor(edad: number): boolean;

		/**
		 * Alias to moreDateThan
		 *
		 * @returns
		 */
		edadMayor(edad: number): boolean;

		/**
		 * Returns true if is null or blank
		 *
		 * @returns
		 */
		esVacio(): boolean;

		/**
		 * Returns true if is not null or blank
		 *
		 * @returns
		 */
		noEsVacio(): boolean;

		/**
		 * Alias to edadMenor
		 *
		 * @returns
		 */
		fechaHaceMenos(edad: number): boolean;

		/**
		 * Alias to edadMayor
		 *
		 * @returns
		 */
		fechaHaceMas(edad: number): boolean;

		/**
		 * Returns true if the date given by this parameter is less than the current date
		 *
		 * @returns
		 */
		fechaMenorHoy(): boolean;

		/**
		 * Returns true if the date given by this parameter is less or equal than the current date
		 *
		 * @returns
		 */
		fechaMenorIgualHoy(): boolean;

		/**
		 * Returns true if the date given by this parameter is greater than the current date
		 *
		 * @returns
		 */
		fechaMayorHoy(): boolean;

		/**
		 * Returns true if the date given by this parameter is greater or equal than the current date
		 *
		 * @returns
		 */
		fechaMayorIgualHoy(): boolean;

		/**
		 * Returns true if the date given by this parameter is less than the date biven by param date
		 *
		 * @param date
		 * @returns
		 */
		fechaMenor(date: string): boolean;

		/**
		 * Returns true if the date given by this parameter is less or equal than the date biven by param date
		 *
		 * @param date
		 * @returns
		 */
		fechaMenorIgual(date: string): boolean;

		/**
		 * Returns true if the date given by this parameter is greater than the date biven by param date
		 *
		 * @param date
		 * @returns
		 */
		fechaMayor(date: string): boolean;

		/**
		 * Returns true if the date given by this parameter is greater or equal than the date biven by param date
		 *
		 * @param date
		 * @returns
		 */
		fechaMayorIgual(date: string): boolean;

		/**
		 * Alias to contains
		 *
		 * @param args
		 * @returns
		 */
		onlyContains(...args: Array<string | number>): boolean;
	}
}

declare global {
	/**
	 * Extension of the Array Interface
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface Array<T> {
		/**
		 * Returns true if the array you pass to it by this parameter contains the string of the this variable.
		 *
		 * @param args
		 * @returns
		 */
		contains(...args: Array<string | number>): boolean;

		/**
		 * Returns true if the array you pass to it by this parameter not contains the string of the this variable.
		 *
		 * @param args
		 * @returns
		 */
		notcontains(...args: Array<string | number>): boolean;

		/**
		 * At least one element of array is Spain
		 *
		 * @returns
		 */
		esEspanya(): boolean;

		/**
		 * At least one element of array is not Spain
		 *
		 * @returns
		 */
		noEspanya(): boolean;

		/**
		 * At least one element of array is in Europe
		 *
		 * @returns
		 */
		esEuropa(): boolean;

		/**
		 * At least one element of array is not in Europe
		 *
		 * @returns
		 */
		noEuropa(): boolean;

		/**
		 * Count how many string dates > 18 years
		 *
		 * @param max
		 */
		numeroAdultos(max: number): boolean;

		/**
		 * Returns true if is null or blank
		 *
		 * @returns
		 */
		esVacio(): boolean;

		/**
		 * Returns true if is not null or blank
		 *
		 * @returns
		 */
		noEsVacio(): boolean;

		/**
		 * Returns true if both arrays are equals
		 *
		 * @param args
		 * @returns
		 */
		onlyContains(...args: Array<string | number>): boolean;

		/**
		 * Alias to edadMenor
		 *
		 * @param edad
		 * @returns
		 */
		fechaHaceMenos(edad: number): boolean;

		/**
		 * Alias to edadMayor
		 *
		 * @param edad
		 * @returns
		 */
		fechaHaceMas(edad: number): boolean;
	}
}

String.prototype.contains = function (...args: Array<string>) {
	for (const arg of args) {
		if (this === String(arg)) {
			return true;
		}
	}
	return false;
};

String.prototype.notcontains = function (...args: Array<string>) {
	return !this.contains(...args);
};

String.prototype.esEspanya = function () {
	return Lists.spainId === this;
};

String.prototype.noEspanya = function () {
	return !this.esEspanya();
};

String.prototype.esEuropa = function () {
	return Lists.europeIds.includes(<any>this);
};

String.prototype.noEuropa = function () {
	return !this.esEuropa();
};

String.prototype.edadMayor = function (edad) {
	return moreDateThan(<string>this, +edad);
};

String.prototype.edadMenor = function (edad) {
	return lessDateThan(<string>this, edad);
};

String.prototype.menorHoy = function () {
	if (!isMatch(<string>this, 'yyyy-MM-dd')) {
		return false;
	}

	return lessDateThanToday(<string>this);
};

// Alias to edadMayor
String.prototype.mayorEdad = function (edad) {
	return moreDateThan(<string>this, +edad);
};

// Alias to edadMenor
String.prototype.menorEdad = function (edad) {
	return lessDateThan(<string>this, edad);
};

String.prototype.fechaMenorHoy = function () {
	if (!isMatch(<string>this, 'yyyy-MM-dd')) {
		return false;
	}

	const today = format(new Date(), 'yyyyMMdd');
	const thisStringDate = format(parseISO(<string>this), 'yyyyMMdd');

	if (thisStringDate < today) {
		return true;
	}

	return false;
};

String.prototype.fechaMenorIgualHoy = function () {
	if (!isMatch(<string>this, 'yyyy-MM-dd')) {
		return false;
	}

	const today = format(new Date(), 'yyyyMMdd');
	const thisStringDate = format(parseISO(<string>this), 'yyyyMMdd');

	if (thisStringDate <= today) {
		return true;
	}

	return false;
};

String.prototype.fechaMayorHoy = function () {
	if (!isMatch(<string>this, 'yyyy-MM-dd')) {
		return false;
	}

	const today = format(new Date(), 'yyyyMMdd');
	const thisStringDate = format(parseISO(<string>this), 'yyyyMMdd');

	if (thisStringDate > today) {
		return true;
	}

	return false;
};

String.prototype.fechaMayorIgualHoy = function () {
	if (!isMatch(<string>this, 'yyyy-MM-dd')) {
		return false;
	}

	const today = format(new Date(), 'yyyyMMdd');
	const thisStringDate = format(parseISO(<string>this), 'yyyyMMdd');

	if (thisStringDate >= today) {
		return true;
	}

	return false;
};

String.prototype.fechaMenor = function (date: string) {
	if (!isMatch(<string>this, 'yyyy-MM-dd')) {
		return false;
	}

	const argumentDate = format(parseISO(date), 'yyyyMMdd');
	const thisStringDate = format(parseISO(<string>this), 'yyyyMMdd');

	if (thisStringDate < argumentDate) {
		return true;
	}

	return false;
};

String.prototype.fechaMenorIgual = function (date: string) {
	if (!isMatch(<string>this, 'yyyy-MM-dd')) {
		return false;
	}

	const argumentDate = format(parseISO(date), 'yyyyMMdd');
	const thisStringDate = format(parseISO(<string>this), 'yyyyMMdd');

	if (thisStringDate <= argumentDate) {
		return true;
	}

	return false;
};

String.prototype.fechaMayor = function (date: string) {
	if (!isMatch(<string>this, 'yyyy-MM-dd')) {
		return false;
	}

	const argumentDate = format(parseISO(date), 'yyyyMMdd');
	const thisStringDate = format(parseISO(<string>this), 'yyyyMMdd');

	if (thisStringDate > argumentDate) {
		return true;
	}

	return false;
};

String.prototype.fechaMayorIgual = function (date: string) {
	if (!isMatch(<string>this, 'yyyy-MM-dd')) {
		return false;
	}

	const argumentDate = format(parseISO(date), 'yyyyMMdd');
	const thisStringDate = format(parseISO(<string>this), 'yyyyMMdd');

	if (thisStringDate >= argumentDate) {
		return true;
	}

	return false;
};

String.prototype.fechaHaceMenos = String.prototype.edadMenor;

String.prototype.fechaHaceMas = String.prototype.edadMayor;

String.prototype.esVacio = function () {
	return this.trim() === '' || this === undefined || this === null;
};

String.prototype.noEsVacio = function () {
	return !this.esVacio();
};

String.prototype.onlyContains = String.prototype.contains;

Array.prototype.contains = function (...args: Array<any>) {
	for (const arg of args) {
		if (this.includes(arg) || this.includes(String(arg))) {
			return true;
		}
	}

	return false;
};

Array.prototype.notcontains = function (...args: Array<any>) {
	return !this.contains(...args);
};

/**
 * At least one element of array is Spain
 */
Array.prototype.esEspanya = function () {
	for (const arg of this) {
		if (String(arg).esEspanya()) {
			return true;
		}
	}

	return false;
};

/**
 * At least one element of array is not Spain
 */
Array.prototype.noEspanya = function () {
	for (const arg of this) {
		if (String(arg).noEspanya()) {
			return true;
		}
	}

	return false;
};

/**
 * At least one element of array is in Europe
 */
Array.prototype.esEuropa = function () {
	for (const arg of this) {
		if (String(arg).esEuropa()) {
			return true;
		}
	}

	return false;
};

/**
 * At least one element of array is not in Europe
 */
Array.prototype.noEuropa = function () {
	for (const arg of this) {
		if (String(arg).noEuropa()) {
			return true;
		}
	}

	return false;
};

/**
 * Count how many string dates > 18 years
 */
Array.prototype.numeroAdultos = function (max) {
	let count = 0;

	for (const arg of this) {
		if (String(arg).edadMayor(18)) {
			count++;
		}
	}

	return count === max;
};

Array.prototype.esVacio = function () {
	return this.length === 0 || this === undefined || this === null;
};

Array.prototype.noEsVacio = function () {
	return !this.esVacio();
};

Array.prototype.onlyContains = function (...args: Array<string | number>) {
	if (this.every(element => args.includes(Number(element)) || args.includes(String(element)))) {
		return true;
	} else {
		return false;
	}
};

Array.prototype.fechaHaceMenos = function (year: number) {
	for (const arg of this) {
		if (String(arg).fechaHaceMenos(year)) {
			return true;
		}
	}

	return false;
};

Array.prototype.fechaHaceMas = function (year: number) {
	for (const arg of this) {
		if (String(arg).fechaHaceMas(year)) {
			return true;
		}
	}

	return false;
};

/**
 * Returns true if more years ago or the same years of the years that are passed by parameter
 *
 * @param date
 * @param ages
 * @returns
 */
const moreDateThan = (date: string, ages: number) => {
	const now = new Date();
	const age = parseISO(date);
	const years = differenceInYears(now, new Date(age));
	if (years >= ages) {
		return true;
	}

	return false;
};

/**
 * Returns true if fewer years ago than the years passed by parameter
 *
 * @param date
 * @param ages
 * @returns
 */
const lessDateThan = (date: string, ages: number) => {
	const now = new Date();
	const age = parseISO(date);
	const years = differenceInYears(now, new Date(age));
	if (years < ages) {
		return true;
	}

	return false;
};

/**
 * Returns true if fewer years ago than current years
 *
 * @param date
 * @returns boolean
 */
const lessDateThanToday = (date: string) => {
	const strNow = format(new Date(), 'yyyyMMdd');
	const strDate = format(parseISO(date), 'yyyyMMdd');
	if (strDate < strNow) {
		return true;
	}

	return false;
};
