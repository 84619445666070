import { createReducer, on, Action } from '@ngrx/store';

import { saveFormResponses, completeStep, init, navigationConfig, saveRequestId, saveExpedient, resetForm } from './shared.actions';
import { ISharedStore } from './shared.state';

/**
 * Initial state
 */
export const initialState: ISharedStore = {
	/**
	 * Step
	 */
	step: 1,

	/**
	 * Substep
	 */
	substep: -1,

	/**
	 * Navigation configuration
	 */
	navigation: {
		isBegin: true,
		isNext: false,
		isDisabledNext: false,
		isLoadingNext: false,
		isDisabledBegin: false,
		isPrevious: false,
		isCancel: true,
		isSubmit: false,
	},
	/**
	 * Steps
	 */
	steps: [
		{
			id: 1,
			text: 'Preguntas previas',
			isActive: true,
			isCompleted: false,
		},
		{
			id: 2,
			text: 'Identificación',
			isActive: false,
			isCompleted: false,
		},
		{
			id: 3,
			text: 'Formulario',
			isActive: false,
			isCompleted: false,
		},
	],
	/**
	 * Saved request id
	 */
	savedRequestId: '',
	/**
	 * Form responses
	 */
	formResponses: {},
	/**
	 * Expedient
	 */
	expedient: {},
};

/**
 * Creates the reducer
 */
const _sharedReducer = createReducer(
	initialState,

	on(navigationConfig, (state, { payload }) => ({
		...state,
		navigation: {
			...state.navigation,
			...payload,
		},
	})),

	on(completeStep, (state, { payload }) => {
		if (payload < 1 || payload > 3) {
			return initialState;
		}
		const index = payload - 1;
		const steps = state.steps.slice(0);
		steps[index] = { ...steps[index], isCompleted: true, isActive: false };
		if (index < 2) {
			steps[index + 1] = { ...steps[index + 1], isActive: true };
		}

		return { ...state, steps };
	}),

	on(saveRequestId, (state, { id }) => ({
		...state,
		savedRequestId: id,
	})),

	on(saveFormResponses, (state, { responses }) => ({
		...state,
		formResponses: { ...state.formResponses, ...responses },
	})),

	on(saveExpedient, (state, { responses }) => ({
		...state,
		expedient: { ...state.expedient, ...responses },
	})),

	on(resetForm, state => ({ ...state, ...initialState })),

	on(init, state => state),
);

/**
 * Shared reducer
 *
 * @param state shared store
 * @param action action
 * @returns shared reducer
 */
export function sharedReducer(state: ISharedStore | undefined, action: Action) {
	return _sharedReducer(state, action);
}
