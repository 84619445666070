import { Navigation, Step } from '../models/shared.model';

/**
 * Feature store name
 */
export const featureStoreName = 'Shared';

/**
 * Navigation actions enum. Contains config
 */
export enum ENavigationActions {
	CONFIG = '[SHARED] - Configure navigation buttons',
}

/**
 * Shared actions enum. Contains init, complete step, save request id, save from responses, save expedient and reset form
 */
export enum ESharedActions {
	INIT = '[SHARED] - Init process',
	COMPLETE_STEP = '[SHARED] - Complete step',
	SAVE_REQUEST_ID = '[SHARED] - Save Request id',
	SAVE_FORM_RESPONSES = '[SHARED] - Save form responses',
	SAVE_expedient = '[SHARED] - Save expedient',
	RESET_FORM = '[SHARED] - Reset form',
}

/**
 * Shared store
 */
export interface ISharedStore {
	/**
	 * Step number
	 */
	step: number;

	/**
	 * Substep number
	 */
	substep: number;

	/**
	 * Navigation
	 */
	navigation: Navigation;

	/**
	 * Steps array
	 */
	steps: Step[];

	/**
	 * Saved request id
	 */
	savedRequestId: string;

	/**
	 * Form responses
	 */
	formResponses: { [key: string]: any };

	/**
	 * Expedient
	 */
	expedient: any;
}
