import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

import { ConfigManager } from '../../config/config';
import { APP_CONFIG } from '../../models/core.model';

@NgModule({
	declarations: [],
	imports: [CommonModule, GoogleTagManagerModule.forRoot({ id: 'googleTagManagerId' })],
	providers: [
		{ provide: APP_CONFIG, useClass: ConfigManager },
		{
			provide: 'googleTagManagerId',
			useFactory: (_injector: Injector) => _injector.get(APP_CONFIG).config.googleTagManager,
			deps: [Injector],
		},
	],
})
export class AnalyticalModule {}
