// Interface
import { InjectionToken } from '@angular/core';

import { ConfigManager } from '../config/config';

/**
 *  Application configuration interface
 */
export interface IAppConfig {
	/**
	 * I18N configuration
	 */
	i18n: {
		scope: II18nScope;
		default: string;
		langs: string[];
	};
	/**
	 * Themes configuration
	 */
	themes: {
		available: string[];
		variants: string[];
		default: string;
		defaultVariant: string;
	};
	/**
	 * Api host congfiguration
	 */
	apiHost: {
		bff: string;
		login: string;
	};
	/**
	 * Release reference
	 */
	release: string;
	/**
	 * Performance reference
	 */
	performance: number;
	/**
	 * CAISS reference
	 */
	caiss: number;
	/**
	 * Login CAISS reference
	 */
	loginCaiss: number;
	/**
	 * Captcha reference
	 */
	captcha: string;
	/**
	 * Prevalidation reference
	 */
	prevalidation: number;
	/**
	 * Use modifications reference
	 */
	useModifications: number;
	/**
	 * Application
	 */
	app: {
		appName: string;
		secret1: string;
		secret2: string;
	};
	/**
	 * LogLevel reference
	 */
	logLevel?: number;
	/**
	 * Google tag manager
	 */
	googleTagManager: string;
}

/**
 * Application enviroment interface
 */
export interface IAppEnv {
	/**
	 * Production reference
	 */
	production: boolean;
	/**
	 * Config file reference
	 */
	configFile: string;
}

/**
 * II18N Scope interface
 */
export interface II18nScope {
	/**
	 * For root reference
	 */
	forRoot: string;
	/**
	 * For child reference
	 */
	forChild: string;
}

/**
 * EI18N Scope enum
 */
export enum EI18nScope {
	/**
	 * For root const reference
	 */
	FOR_ROOT = 'forRoot',
	/**
	 * For child const reference
	 */
	FOR_CHILD = 'forChild',
}

/**
 * Request GISS interface
 */
export interface RequestGiss {
	/**
	 * Relay state reference
	 */
	RelayState: string;
	/**
	 * SAML request reference
	 */
	SAMLRequest: string;
}

// Tokens
/**
 * Application configuration token
 */
export const APP_CONFIG = new InjectionToken<ConfigManager>('APP_CONFIG');
/**
 * Application environment token
 */
export const APP_ENVIRONMENT = new InjectionToken<IAppEnv>('APP_ENVIRONMENT');

// I18n
/**
 * I18N scope token
 */
export const I18N_SCOPE = new InjectionToken<EI18nScope>('I18N_SCOPE');
/**
 * I18N file token
 */
export const I18N_FILE = new InjectionToken<string>('I18N_FILE');
