import { createSelector } from '@ngrx/store';
import { AppStore } from '@shared/models';

import { featureStoreName, ICoreStore } from './core.state';

/**
 * Core selectors
 */
export interface ICoreAppStore extends AppStore {
	/**
	 * Feature store name
	 */
	[featureStoreName]: ICoreStore;
}

/**
 * Core store select
 *
 * @param state Core app store
 * @returns core state
 */
export const selectCoreStore = (state: ICoreAppStore) => state[featureStoreName];

/**
 * Language select
 */
export const selectLang = createSelector(selectCoreStore, (state: ICoreStore) => state?.i18n?.currentLang);

/**
 * Theme select
 */
export const selectTheme = createSelector(selectCoreStore, (state: ICoreStore) => state?.theme);
