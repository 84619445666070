import { Injectable } from '@angular/core';
import { SwUpdate, UpdateAvailableEvent } from '@angular/service-worker';
import { Observable } from 'rxjs';

/**
 * Update Service
 */
@Injectable({
	providedIn: 'root',
})
export class UpdateService {
	constructor(private _update: SwUpdate) {}

	checkUpdateObsolete(): Observable<UpdateAvailableEvent> {
		return this._update.available;
	}
}
