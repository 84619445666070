<div class="imv-error">
	<div class="imv-error__content">
		<h1 class="imv-error__title">{{ error.title | translate }}</h1>
		<p class="imv-error__text">{{ error.message | translate }}</p>
		<imv-ui-button eid="error500-navigate-home-button" class="imv-error__button" theme="primary" variant=" mdc-button--modal" (click)="navigateHome()"
			><span slot="text">Ir a Inicio</span></imv-ui-button
		>
	</div>
	<img class="imv-error__image" src="./assets/pwa/error/404-page-not-found-flatline.png" alt="Error imagen" />
</div>
