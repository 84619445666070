import { Injectable } from '@angular/core';

/**
 * Token service injectable
 */
@Injectable({ providedIn: 'root' })
export class TokenService {
	/**
	 * Token string
	 */
	private _token!: string;

	/**
	 * CAISS boolean
	 */
	private _caiss = false;

	/**
	 * Empty constructor
	 */
	constructor() {
		// Empty constructor
	}

	/**
	 * Gets the token
	 *
	 * @returns token
	 */
	getToken(): string {
		return this._token;
	}

	/**
	 * Sets the token
	 *
	 * @param token token
	 */
	setToken(token: string) {
		this._token = token;
	}

	/**
	 * Enable the CAISS by setting the CAISS boolean to true
	 */
	enableCaiss() {
		this._caiss = true;
	}

	/**
	 * Checks if the user is CAISS
	 *
	 * @returns true if the user is CAISS
	 */
	isCaiss() {
		return this._caiss;
	}
}
