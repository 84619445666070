const languages = [
	{
		checked: false,
		option: 'Castellano',
		text: 'Castellano',
		value: 'es',
	},
	{
		checked: false,
		option: 'English',
		text: 'English',
		value: 'en',
	},
	{
		checked: false,
		option: 'Català',
		text: 'Català',
		value: 'ca',
	},
	{
		checked: false,
		option: 'Euskara',
		text: 'Euskara',
		value: 'eu',
	},
	{
		checked: false,
		option: 'Galego',
		text: 'Galego',
		value: 'gl',
	},
	{
		checked: false,
		option: 'Valencià',
		text: 'Valencià',
		value: 'va',
	},
];
export { languages };
