import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { applyPolyfills, defineCustomElements } from '@imv/ui-kit/loader';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import './global-extension';

if (environment.production) {
  enableProdMode();
}
Promise.all([platformBrowserDynamic().bootstrapModule(AppModule), applyPolyfills()])
  .then(() => defineCustomElements())
  .catch(err => console.error(err));
