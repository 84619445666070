import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Injectable, Injector } from '@angular/core';
import { of, Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { selectLang } from '../../store/core.selectors';

/**
 * Instance Manager Service
 */
@Injectable()
export class InstanceManagerService {
	/**
	 * Translate service for Root instance
	 */
	private _rootInstance!: TranslateService;

	/**
	 * Instance map
	 */
	private _instanceMap = new Map();

	/**
	 * Instance Manager constructor
	 *
	 * @param _injector injector
	 * @param router router
	 */
	constructor(
		private _injector: Injector,
		private router: Router,
	) {}

	/**
	 * Initializes the manager
	 */
	init(): void {
		this._injector
			.get(Router)
			.events.pipe(
				filter(e => e instanceof NavigationEnd),
				map(() => this._injector.get(ActivatedRoute)),
				map(route => {
					while (route.firstChild) {
						route = route.firstChild;
					}
					return route;
				}),
				switchMap(route => route.data),
				map((data: any) => data?.module),
				switchMap(module =>
					this._injector
						.get(Store)
						.select(selectLang)
						.pipe(switchMap((lang = 'es') => of([lang, module]))),
				),
				switchMap(data => this._pageTitle(data[1])),
			)
			.subscribe({ next: (pageTitle: string) => pageTitle });
	}

	/**
	 * Gets the title page
	 *
	 * @param obj object
	 * @returns title page
	 */
	_pageTitle(obj: any): Observable<string> {
		if (this._instanceMap.get(obj)) {
			return this._instanceMap.get(obj).get('title');
		}

		return of('');
	}

	/**
	 * Sets the root instance
	 *
	 * @param instance translate service
	 */
	setRootInstance(instance: TranslateService): void {
		this._rootInstance = instance;
	}

	/**
	 * Sets the features instance
	 *
	 * @param module module string
	 * @param instance translate service
	 */
	setFeatureInstance(module: string, instance: TranslateService): void {
		this._instanceMap.set(module, instance);
	}

	// private _setTitle(pageTitle: string): void {
	// 	const title = pageTitle.length > 0 ? ['IMV', pageTitle].join(' | ') : pageTitle;
	// 	this._injector.get(Title).setTitle(title);
	// }
}
