/* eslint-disable no-prototype-builtins */
import { Behaviour, FormFieldDto, Validation } from '../dtos/form.dto';
import { CoreExpressions } from '@app/core';

const TYPE_IDENTIFICATION = 'identificacion-tipo';
const VIDEO_IDENTIFICATION = 'video-identification';
/**
 * Form field model
 */
export class FormField implements FormFieldDto {
	/**
	 * Question id
	 */
	idPregunta = 0;
	/**
	 * Variable code
	 */
	codigoVariable = '';
	/**
	 * Response type
	 */
	tipoRespuesta = 'texto';
	/**
	 * Name
	 */
	nombre = '';
	/**
	 * Description
	 */
	descripcion = '';
	/**
	 * Key list
	 */
	keyLista!: any[] | string;
	/**
	 * key text
	 */
	keyTexto: any[] = [];
	/**
	 * Visibility condition
	 */
	expresionVisibilidad = '';
	/**
	 * Required
	 */
	obligatoria = '';
	/**
	 * Min length Not used anymore
	 */
	longitudMin = 0;
	/**
	 * Maximum number of characters
	 */
	longitudMax = 2000;
	/**
	 * Minimum number (for inputs expecting a number)
	 */
	valorMin = 0;
	/**
	 * Maximum number (for inputs expecting a number)
	 */
	valorMax = 100;
	/**
	 * Active boolean
	 */
	activa = 1;
	/**
	 * Order
	 */
	orden = 0;
	/**
	 * Dependence
	 */
	depende = '';
	/**
	 * Default value
	 */
	valorPorDefecto = '1';
	/**
	 * Repeat field
	 */
	repetirN = '';
	/**
	 * Parameters array
	 */
	parametros: any[] = [];
	/**
	 * Disabled boolean
	 */
	disabled = '0';
	/**
	 * Amounts array
	 */
	importes: any[] = [];
	/**
	 * Context
	 */
	contexto = '';
	/**
	 * Validations array
	 */
	validaciones?: Validation[] = [];
	/**
	 * Behaviours array
	 */
	comportamientos?: Behaviour[] = [];

	/**
	 * Original default value
	 */
	valorPorDefectoOriginal!: string;

	/**
	 * Original key list
	 */
	keyListaOriginal!: any[] | string;

	/**
	 * Original disabled
	 */
	disabledOriginal!: string;

	/**
	 * original validations array
	 */
	validacionesOriginal!: Validation[];

	/**
	 * Key
	 */
	key!: string;
	/**
	 * Options array
	 */
	options: OptionGender[] | OptionId[] | OptionBoolean[] = [];
	/**
	 * Is visible boolean
	 */
	isVisible: boolean;
	/**
	 * Has conditions boolean
	 */
	hasCondition: boolean;
	/**
	 * Is required boolean
	 */
	isRequired: boolean;
	/**
	 * Has required condition boolean
	 */
	hasRequiredCondition: boolean;
	/**
	 * Is disabled boolean
	 */
	isDisabled: boolean;
	/**
	 * Has disable condition boolean
	 */
	hasDisabledCondition: boolean;
	/**
	 * Has description condition boolean
	 */
	hasDescriptionCondition: boolean;
	/**
	 * Is multiple boolean
	 */
	isMultiple: boolean;
	/**
	 * Repeat count
	 */
	repeatCount = 0;
	/**
	 * Index
	 */
	index = 0;
	/**
	 * Has parameters boolean
	 */
	hasParameters!: boolean;
	/**
	 * Parent key
	 */
	parentKey!: string;
	/**
	 * Children array
	 */
	children: FormField[] = [];
	/**
	 * Document type
	 */
	documentType = 'dni';
	/**
	 * Backend error
	 */
	backendError = '';
	/**
	 * Classes array
	 */
	classes: string[] = [];
	/**
	 * Control field value
	 */
	controlFieldValue = '';
	/**
	 * Control field key
	 */
	controlFieldKey = '';
	/**
	 * Control field default value
	 */
	controlFieldDefaultValue = '';

	/**
	 * Constructor. Initializes the booleans and arrays
	 */
	constructor() {
		this.isVisible = true;
		this.isRequired = false;
		this.isDisabled = false;
		this.hasCondition = false;
		this.hasRequiredCondition = false;
		this.hasDisabledCondition = false;
		this.hasDescriptionCondition = false;
		this.isMultiple = false;
		this.children = [];

		this.options = [];
	}

	/**
	 * Formats the form field
	 *
	 * @param data backend format form field
	 * @returns formatted form field
	 */
	static fromDto(data: FormFieldDto): FormFieldExt {
		const obj: FormFieldExt = new FormField() as FormFieldExt;
		FormField.setDataPropertiesInObj(data, obj);

		obj.key = obj.codigoVariable;

		obj.tipoRespuesta = obj.tipoRespuesta.toLowerCase();

		// Behaviours default value
		FormField.setBehavioursToObj(obj);

		if (Array.isArray(obj.keyTexto) && obj.keyTexto.length > 0) {
			obj.hasDescriptionCondition = true;
		}

		obj.expresionVisibilidad = obj.expresionVisibilidad || '';
		if (obj.expresionVisibilidad.trim() !== '') {
			obj.hasCondition = true;
			obj.isVisible = false;
		}

		obj.obligatoria = obj.obligatoria || '';
		if (obj.obligatoria === '' || obj.obligatoria === '0' || obj.obligatoria === '1') {
			obj.isRequired = obj.obligatoria === '1';
		} else if (obj.obligatoria !== '') {
			obj.isRequired = false;
			obj.hasRequiredCondition = true;
		}

		obj.disabled = (obj.disabled || '').trim();
		if (obj.disabled !== '' && obj.disabled !== '0') {
			obj.hasDisabledCondition = true;
			obj.isDisabled = false;
		}

		if (obj.repetirN.trim()) {
			obj.isMultiple = true;
		}

		if (obj.parametros?.length) {
			obj.hasParameters = true;
		}

		if (obj.keyLista === TYPE_IDENTIFICATION) {
			obj.tipoRespuesta = obj.keyLista;
		}

		FormField.setRadioAndSelectInObj(obj);

		obj.nombre = obj._cleanInvalidChars(obj.nombre);

		// Set original values (to be able to change current values with behaviours without losing the originals)
		obj.valorPorDefectoOriginal = obj.valorPorDefecto;
		obj.disabledOriginal = obj.disabled;
		obj.keyListaOriginal = obj.keyLista;
		obj.validacionesOriginal = obj.cloneValidations(obj.validaciones);

		obj.setClasses();

		return obj;
	}

	private static setRadioAndSelectInObj(obj: FormFieldExt) {
		if (['radio', 'select'].includes(obj.getType()) && Array.isArray(obj.keyLista)) {
			obj.options = obj.keyLista.map(item => ({
				key: obj.codigoVariable,
				value: String(item.valor),
				text: obj._cleanInvalidChars(item.opcion),
				checked: false,
				description: item.descripcion || '',
				fullDescription: item.descripcionAmpliada || '',
			}));
		}

		if (obj.getType() === 'select' && !Array.isArray(obj.keyLista)) {
			obj.options = obj._createOptions();
		}

		if (obj.tipoRespuesta === 'boolean') {
			obj.options = [
				{ value: 1, text: 'SI', key: obj.codigoVariable, checked: false },
				{ value: 2, text: 'NO', key: obj.codigoVariable, checked: false },
			];
		}
	}

	private static setBehavioursToObj(obj: FormFieldExt) {
		if (!obj.hasOwnProperty('comportamientos') || !Array.isArray(obj.comportamientos)) {
			obj.comportamientos = [];
		}

		// Sort behaviours by orden property
		if (obj.comportamientos.length && obj.comportamientos.every(behaviour => behaviour.orden)) {
			obj.comportamientos.sort((a, b) => (a.orden >= b.orden ? 1 : -1));
		}
	}

	private static setDataPropertiesInObj(data: FormFieldDto, obj: FormFieldExt) {
		for (const property in data) {
			obj[property] = data[property as keyof FormFieldDto];
		}
	}

	/**
	 * Clones validations
	 *
	 * @param validations validations array
	 * @returns cloned validations
	 */
	public cloneValidations(validations: Validation[]): Validation[] {
		return validations.map(validation => ({ ...validation }));
	}

	/**
	 * Applies behaviours
	 *
	 * @param responses responses
	 * @param variables variables
	 * @returns
	 */
	public applyBehaviours(responses: any, variables: any) {
		let didBehaviourApply = false;
		if (!this.comportamientos || this.comportamientos.length === 0) {
			return;
		}
		this.comportamientos.forEach(behaviour => {
			if (CoreExpressions.evaluate(behaviour.condicion, responses, variables)) {
				this.setBehaviour(behaviour);
				didBehaviourApply = true;
			}
		});

		if (!didBehaviourApply) {
			// Revert possible changes to default values
			this.valorPorDefecto = this.valorPorDefectoOriginal;
			this.disabled = this.disabledOriginal;
			this.keyLista = this.keyListaOriginal;
			this.validaciones = this.cloneValidations(this.validacionesOriginal);
		}
	}

	private setBehaviour(behaviour: Behaviour) {
		const hasDefaultValue = behaviour.valorPorDefecto !== null;
		const isDisabled = behaviour.disabled !== null;
		const hasKeyLista = behaviour.keyLista !== null;
		this.valorPorDefecto = hasDefaultValue ? behaviour.valorPorDefecto : this.valorPorDefectoOriginal;
		this.disabled = isDisabled ? behaviour.disabled : this.disabledOriginal;
		this.keyLista = hasKeyLista ? behaviour.keyLista : this.keyListaOriginal;
		this.validaciones = behaviour.validaciones !== null ? this.cloneValidations(behaviour.validaciones) : this.cloneValidations(this.validacionesOriginal);
	}

	/**
	 * Repeat the field n times
	 */
	public repeat(index: number) {
		const fields = [];
		this.isMultiple = true;

		this.children = [];
		for (let i = 1; i <= index; i++) {
			const field = this.clone(i);
			field.isMultiple = true;
			field.nombre = field.nombre.replace('{index}', `${i}`);
			fields.push(field);
		}

		this.children = fields;
	}

	/**
	 * Special Repeat for the field PF27
	 */
	public repeatRelationMembers(index: number) {
		const fields = [];
		this.isMultiple = true;

		this.children = [];
		for (let i = 0; i <= index; i++) {
			if (i !== this.index) {
				const field = this.clone(i);
				field.repetirN = '';
				field.children = [];
				field.isMultiple = true;
				if (i === 0) {
					field.nombre = 'Solicitante';
				} else {
					field.nombre = field.nombre.replace('{index}', `${i}`);
				}
				fields.push(field);
			}
		}

		this.children = fields;
	}

	/**
	 * Clone a FormField.
	 *
	 * Use index > 0 for repeatition, replace the '_index' with tehe current position
	 * Use index = -1 for clone, with same properties
	 *
	 * @param index
	 * @returns FormField
	 */
	public clone(index = -1): FormFieldExt {
		const field: FormFieldExt = new FormField() as FormFieldExt;
		const parentKey = this.key;
		const obj: FormFieldExt = { ...(this as any) };

		for (const property in obj) {
			if (obj.hasOwnProperty(property)) {
				field[property] = this[property as keyof this];
			}
		}

		field.children = [];
		if (index >= 0) {
			field.index = index;
			field.parentKey = parentKey;
			field.key = parentKey + '_' + index;
			field.obligatoria = field.obligatoria.replace(/_index/g, `_${index}`);
			field.expresionVisibilidad = field.expresionVisibilidad.replace(/_index/g, `_${index}`);
			field.comportamientos = field.comportamientos?.map(behaviour => ({ ...behaviour, condicion: behaviour.condicion.replace(/_index/g, `_${index}`) }));
			field.repetirN = field.repetirN.replace(/_index/g, `_${index}`);
			field.depende = field.depende.replace(/_index/g, `_${index}`);
			field.valorPorDefecto = field.valorPorDefecto.replace(/_index/g, `_${index}`);
		}

		return field;
	}

	/**
	 * Gets the type
	 *
	 * @returns type
	 */
	public getType() {
		const textTypes = ['texto abierto', 'alfabetico', 'alfanumerico', 'texto', 'number', 'telefono', 'numeroDeRegistro'];
		const selectorTypes = ['selector', 'lista selección', 'lista selección relacionada', TYPE_IDENTIFICATION];

		if (textTypes.includes(this.tipoRespuesta)) {
			return 'text';
		}

		if (selectorTypes.includes(this.tipoRespuesta)) {
			return 'select';
		}

		if (this.tipoRespuesta === 'fecha') {
			return 'date';
		}

		if (this.tipoRespuesta === 'fechacaducidad') {
			return 'fechacaducidad';
		}

		if (this.tipoRespuesta === 'radio') {
			return 'radio';
		}

		if (this.tipoRespuesta === 'boolean') {
			return 'radio';
		}

		if (this.tipoRespuesta === 'fichero') {
			return 'file';
		}

		if (this.tipoRespuesta === 'email') {
			return 'email';
		}

		if (this.tipoRespuesta === 'check') {
			return 'check';
		}

		if (this.tipoRespuesta === 'control') {
			return 'control';
		}

		if (this.tipoRespuesta === 'textarea') {
			return 'textarea';
		}

		if (this.tipoRespuesta === 'info') {
			return 'info';
		}

		if (this.tipoRespuesta === VIDEO_IDENTIFICATION) {
			return VIDEO_IDENTIFICATION;
		}

		if (this.tipoRespuesta === 'dni-image') {
			return 'dni-image';
		}

		return 'text';
	}

	/**
	 * Gets the IBAN prefix
	 *
	 * @returns IBAN prefix
	 */
	public getPrefix() {
		return this.tipoRespuesta === 'iban' ? 'ES' : null;
	}

	/**
	 * Gets the classes
	 *
	 * @returns classes array
	 */
	public getClasses(): Array<string> {
		return this.classes;
	}

	/**
	 * Sets the clases
	 */
	public setClasses() {
		const classes: string[] = [];
		const radioWidth = 'imv-ui-selector-list--radio';
		const doubleWidth = 'imv-form__field--double';
		const fileClass = 'imv-form__field--file';
		const fullWidth = 'imv-form__field--full';
		const textAreaWidth = 'imv-form__field--textarea-full';
		const dniIdentification = 'imv-form__field--dni-identification';

		const type = this.getType();

		if (this.tipoRespuesta === 'selector' && this.keyLista === 'estado-civil') {
			classes.push(doubleWidth);
		}

		if (this.tipoRespuesta === 'email' || this.tipoRespuesta === 'iban') {
			classes.push(doubleWidth);
		}

		if (this.tipoRespuesta === 'boolean' || this.tipoRespuesta === 'radio') {
			classes.push(radioWidth);
		}

		if (type === 'file') {
			classes.push(fileClass);
			classes.push(fullWidth);
		}

		if (type === 'check' || type === 'info' || type === VIDEO_IDENTIFICATION) {
			classes.push(fullWidth);
		}

		if (type === 'textarea') {
			classes.push(textAreaWidth);
		}

		if (type === 'dni-image') {
			classes.push(dniIdentification);
		}

		this.classes = classes;
	}

	/**
	 * Gets the tooltip
	 *
	 * @returns true if a tooltip is needed
	 */
	public getTooltip(): boolean {
		if (this.tipoRespuesta === 'identificador-soporte' || this.tipoRespuesta === 'identificacion-soporte') {
			return true;
		}

		return !!this.descripcion;
	}

	/**
	 * Gets the tooltip type
	 *
	 * @returns tooltip type
	 */
	public getTooltipType(): string {
		if (this.tipoRespuesta === 'identificador-soporte' || this.tipoRespuesta === 'identificacion-soporte') {
			return this.documentType;
		}

		return this.descripcion;
	}

	/**
	 * Creates the options
	 *
	 * @returns options
	 */
	private _createOptions(): OptionId[] | OptionGender[] {
		let options: OptionId[] | OptionGender[] = [];

		if (this.keyLista === 'identificador-tipo' || this.keyLista === TYPE_IDENTIFICATION) {
			options = [
				{ value: '1', text: 'DNI', option: 'DNI', checked: false },
				{ value: '6', text: 'NIE', option: 'NIE', checked: false },
			];
		} else if (this.keyLista === 'genero') {
			options = [
				{ value: '1', text: 'Hombre', checked: false },
				{ value: '2', text: 'Mujer', checked: false },
			];
		}

		return options;
	}

	/**
	 * Cleans invalid chars of a string
	 *
	 * @param text text
	 * @returns clean text
	 */
	private _cleanInvalidChars(text: string): string {
		// Remove unicode spaces
		// eslint-disable-next-line no-irregular-whitespace
		text = text.replace(/[\u00A0\u1680​\u180e\u2000-\u2009\u200a​\u200b​\u202f\u205f​\u3000]/g, '');

		return text;
	}
}

interface FormFieldExt extends FormField {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
	validaciones: Validation[];
}

interface OptionGender {
	value: string;
	text: string;
	checked: boolean;
}

interface OptionId extends OptionGender {
	option: string;
}
interface OptionBoolean {
	value: number;
	text: string;
	checked: boolean;
	key: string;
}
