import { switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';

import { ESharedActions } from './shared.state';
import { initQuestionnaire } from '@app/questionnaire';

/**
 * Shared effects
 */
@Injectable()
export class SharedEffects {
	/**
	 * Initializes the process
	 */
	initProcess$ = createEffect(() =>
		this._actions$.pipe(
			ofType(ESharedActions.INIT),
			switchMap(() => [initQuestionnaire()]),
		),
	);

	/**
	 * Shared effects constructor
	 *
	 * @param _actions$ actions
	 */
	constructor(private _actions$: Actions) { }
}
