import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CommonNavigationService } from './common-navigation.service';

/**
 * Common-Navigation abstract service
 */
@Injectable({
	providedIn: 'root',
	useClass: CommonNavigationService,
	deps: [],
})
export abstract class AbstractCommonNavigationService {
	/**
	 * Converts subject next button as observable
	 */
	abstract next(): Observable<boolean>;

	/**
	 * Converts subject previous button as observable
	 */
	abstract previous(): Observable<boolean>;

	/**
	 * Converts subject cancel button as observable
	 */
	abstract cancel(): Observable<boolean>;

	/**
	 * Converts subject submit button as observable
	 */
	abstract submit(): Observable<boolean>;

	/**
	 * Converts subject progressbar button as observable
	 */
	abstract progressBar(): Observable<number>;

	/**
	 * Emits a change on next button subject
	 */
	abstract nextButton(): void;

	/**
	 * Emits a change on previous button subject
	 */
	abstract previousButton(): void;

	/**
	 * Emits a change on cancel button subject
	 */
	abstract cancelButton(): void;

	/**
	 * Emits a change on submit button subject
	 */
	abstract submitButton(): void;

	/**
	 * Sets progresbar to new value passed by param
	 *
	 * @param value value number
	 */
	abstract setProgressBar(value: number): void;

	/**
	 * Sets progressbar in questionnaire
	 *
	 * @param isIncrement is increment boolean
	 */
	abstract setProgressBarInQuestionnaire(isIncrement: boolean): void;

	/**
	 * Sets progressbar in form
	 *
	 * @param currentStep current form step
	 * @param totalSteps total number of steps
	 */
	abstract setProgressBarInForm(currentStep: number, totalSteps: number): void;
}
