import { Routes } from '@angular/router';
import { Error404Component } from './shared/components/error/error404/error404.component';
import { Error500Component } from './shared/components/error/error500/error500.component';
import { GTMService } from './core/modules/analytical/google-tag-manager.service';

export const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./pages/home/home.module').then(module => module.HomeModule),
		data: {
			home: true,
			title: [GTMService.TENANT, 'Ingreso Mínimo Vital'].join(GTMService.SPLITTER),
		},
		pathMatch: 'full',
	},
	{
		path: 'home',
		redirectTo: '',
		pathMatch: 'full',
	},
	{
		path: 'appweb',
		redirectTo: '',
		pathMatch: 'full',
	},
	{
		path: 'questionnaire',
		loadChildren: () => import('./pages/questionnaire/home/questionnaire-home.module').then(module => module.QuestionnaireHomeModule),
		data: {
			home: false,
			title: [GTMService.TENANT, GTMService.QUESTIONARY, 'Bienvenido/a al servicio de simulación y solicitud del IMV'].join(GTMService.SPLITTER),
		},
	},
	{
		path: 'form',
		loadChildren: () => import('./pages/form/home/home.module').then(module => module.HomeModule),
		data: {
			home: false,
			title: [GTMService.TENANT, GTMService.FORM].join(GTMService.SPLITTER),
		},
	},
	{
		path: 'request',
		loadChildren: () => import('./pages/request/home/home.module').then(module => module.HomeModule),
		data: {
			home: false,
			title: [GTMService.TENANT, 'Accede al borrador'].join(GTMService.SPLITTER),
		},
	},
	{
		path: 'consult',
		loadChildren: () => import('./pages/consult-request/home/home.module').then(module => module.HomeModule),
		data: {
			home: false,
			title: [GTMService.TENANT, 'Gestiona la solicitud'].join(GTMService.SPLITTER),
		},
	},
	{
		path: 'proceedings',
		loadChildren: () => import('./pages/proceedings/proceedings.module').then(module => module.ProceedingsModule),
		data: {
			home: false,
			title: [GTMService.TENANT, 'Procedimiento'].join(GTMService.SPLITTER),
			isProceedings: true,
		},
	},
	{
		path: 'logingiss',
		loadChildren: () => import('./pages/login/login.module').then(module => module.LoginModule),
		data: {
			home: true,
			title: [GTMService.TENANT, 'Iniciar Sesión'].join(GTMService.SPLITTER),
		},
	},
	{
		path: 'solicitud',
		loadChildren: () => import('./pages/redirect/redirect.module').then(module => module.RedirectModule),
		data: {
			home: true,
			title: [GTMService.TENANT, 'Solicitud'].join(GTMService.SPLITTER),
		},
	},
	{
		path: 'update-status',
		loadChildren: () => import('./pages/redirect-status/redirect-status.module').then(module => module.RedirectStatusModule),
		data: {
			home: true,
			title: [GTMService.TENANT, 'Estado de la actualización'].join(GTMService.SPLITTER),
		},
	},
	{
		path: '404',
		component: Error404Component,
		data: {
			error404: true,
			title: [GTMService.TENANT, 'Recurso no encontrado'].join(GTMService.SPLITTER),
			home: false,
		},
	},
	{
		path: '500',
		component: Error500Component,
		data: {
			error500: true,
			title: [GTMService.TENANT, 'Error del sistema'].join(GTMService.SPLITTER),
			home: false,
		},
	},
];
