import { Subject } from 'rxjs';
import { FormManager } from '@shared/managers';

/**
 * Checks if the token exists and has 8 characters.
 *
 * @param token The token to be checked.
 * @returns Whether or not the token is set and has 8 characters.
 */
export const isValidTokenParam = (token: string): boolean => {
	return !!token && `${token}`.length === 8;
};

/**
 * Gets the key of a questions using its context field.
 *
 * @param formManager FormManager holding the fields for the current step.
 * @param context The context to be searched in the current step's questions.
 * @returns The key with the specified context.
 */
export const getKeyByContext = (formManager: FormManager, context: string): string | undefined =>
	Object.keys(formManager.fields).find(key => formManager.fields[key].contexto === context);

/**
 * Check if data is an empty array or array full of empty strings, undefined or null
 *
 * @param data Input to check if is empty array
 * @returns true if data is empty array or array full of empty strings or array with nulls or undefined only
 */
export const isEmptyArray = (data: any) => {
	return Array.isArray(data) && data.every(item => ((typeof item === 'string' || item instanceof String) && item.length === 0) || item === null || item === undefined);
};

/**
 * Core utils. Called on destroy
 *
 * @param constructor constructor
 */
export const TakeUntilDestroy = (constructor: any): void => {
	const originalDestroy = constructor.prototype.ngOnDestroy;

	if (typeof originalDestroy !== 'function') {
		console.warn(`${constructor.name} is using @TakeUntilDestroy but does not implement OnDestroy`);
	}

	constructor.prototype._subscriptionDestroy = function (): Record<string, unknown> {
		this._takeUntilDestroy$ = this._takeUntilDestroy$ || new Subject();
		return this._takeUntilDestroy$.asObservable();
	};

	constructor.prototype.ngOnDestroy = function (...args: any[]): void {
		if (typeof originalDestroy === 'function') {
			originalDestroy.apply(this, args);
		}
		if (this._takeUntilDestroy$) {
			this._takeUntilDestroy$.next();
			this._takeUntilDestroy$.complete();
		}
	};
};
