import { IIdentification } from '../model/identification.model';

/**
 * Feature store name
 */
export const featureStoreName = 'Identification';

/**
 * Identification action enum. Contains save
 */
export enum EIdentificationActions {
	SAVE = '[Identification] Save user',
}

/**
 * Identification store
 */
export interface IIdentificationStore {
	/**
	 * User
	 */
	user: IIdentification;
}
