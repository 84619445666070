import { Injectable } from '@angular/core';

import { CommonUtilsApiModule } from './common-utils-api.module';
import { CommonUtilsService } from './common-utils.service';

/**
 * Common utils abstract service
 */
@Injectable({
	providedIn: CommonUtilsApiModule,
	useClass: CommonUtilsService,
	deps: [],
})
export abstract class AbstractCommonUtilsService {
	/**
	 * Gets the module name
	 *
	 * @param name name
	 */
	abstract getModuleName(name: string): string;
}
