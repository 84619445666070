import { createAction, props } from '@ngrx/store';
import { BaremosDto, RefusalDto } from '@shared/dtos';
import { FormField, QuestionResponse } from '@shared/models';
import { EHomeActions } from './home.state';

/**
 * Public. Loads the questions
 */
export const loadQuestions = createAction(EHomeActions.LOAD_QUESTIONS);

/**
 * Public. Initializes the questionnaire
 */
export const initQuestionnaire = createAction(EHomeActions.INIT_QUESTIONNAIRE);

/**
 * Public. Gets next question
 */
export const nextQuestion = createAction(EHomeActions.NEXT_QUESTION, props<{ payload: QuestionResponse }>());

/**
 * Public. Gets previous question
 */
export const previousQuestion = createAction(EHomeActions.PREVIOUS_QUESTION);

/**
 * Public. Cancels the questionnaire
 */
export const cancelQuestionnaire = createAction(EHomeActions.CANCEL_QUESTIONNAIRE);

/**
 * Public. Generate the questions
 */
export const generateQuestions = createAction(EHomeActions.GENERATE_QUESTIONS);

/**
 * Private. Loads success questions
 */
export const loadQuestionsSuccess = createAction(EHomeActions.LOAD_QUESTIONS_SUCCESS, props<{ questions: FormField[]; baremos: BaremosDto; refusals: RefusalDto[] }>());

/**
 * Private. Loads fail questions
 */
export const loadQuestionsFail = createAction(EHomeActions.LOAD_QUESTIONS_FAIL);

/**
 * Private. Sets the question
 */
export const setQuestion = createAction(EHomeActions.SET_QUESTION, props<{ payload: number }>());

/**
 * Private. Adds a response
 */
export const addResponse = createAction(EHomeActions.ADD_RESPONSE, props<{ key: string; value: string; parent: string }>());

/**
 * Private. Removes last response
 */
export const removeLastResponse = createAction(EHomeActions.REMOVE_LAST_RESPONSE);

/**
 * Private. Save the questions
 */
export const saveQuestions = createAction(EHomeActions.SAVE_QUESTIONS, props<{ payload: FormField[] }>());
