/**
 * Class used to autocomplete matrix (array of ararys).
 */
export class CoreMatrix {
	/**
	 * Opposite map
	 */
	opposite!: Map<string, string>;

	/**
	 * Empty constructor
	 */
	constructor() {
		/**
		 * Empty constructor
		 */
	}

	/**
	 * Stores the opposites information from APPIAN.
	 *
	 * @param opposites The information with the oposites.
	 */
	setOpposites(opposites: [string, string][]): void {
		this.opposite = new Map(opposites);
	}

	/**
	 * Finds the opposite to one item of the matrix.
	 *
	 * @param original The item in the matrix to look for it's opposite.
	 * @returns The oposite item.
	 */
	getOpposite(original: string): string {
		return this.opposite.get(original) || 'undefined';
	}

	/**
	 * Finds the opposite index in the matrix.
	 *
	 * @param key The index to look for its opposite index.
	 * @returns The opposite index.
	 */
	getInvertedKey(key: string): string {
		const arr = key.split('_');

		return arr[0] + '_' + arr[2] + '_' + arr[1];
	}

	/**
	 * Verifies if the autocomplete may create an infinite loop.
	 *
	 * @param currentKey Current key triggering an autocomplete change.
	 * @param previousTriggerKey Previous key that triggered the autocomplete.
	 * @returns Wheter or not this autocomplete would create an infinite loop.
	 */
	createsLoop(currentKey: string, previousTriggerKey: string): boolean {
		return this.isGrandchild(currentKey) && this.isGrandchild(previousTriggerKey) && this.areInvertedInMatrix(currentKey, previousTriggerKey);
	}

	/**
	 * Checks if the two keys are inverted in the matrix.
	 *
	 * @param key1 First key.
	 * @param key2 Second key.
	 * @returns Whether or not the two keys are inverted in the matrix.
	 */
	areInvertedInMatrix(key1: string, key2: string): boolean {
		const key1arr = key1.split('_');
		const key2arr = key2.split('_');

		const from1 = key1arr[1];
		const from2 = key2arr[1];
		const to1 = key1arr[2];
		const to2 = key2arr[2];

		return from1 === to2 && to1 === from2;
	}

	/**
	 * Checks if the provided key is a grandchild (PFX_Y_Z)
	 *
	 * @param key The key to be inspected.
	 * @returns Whether or not the passed key is a grandchild.
	 */
	isGrandchild(key: string): boolean {
		return /PF\d+_\d+_\d+/.test(key);
	}

	/**
	 * Checks if the key is a relationship with the applicant.
	 * The applicant has the special value 0 as the last number in the key.
	 *
	 * @param key The key to be inspected.
	 * @returns Whether or not the this key is a relationship with the applicant.
	 */
	isRelationWithApplicant(key: string) {
		return /PF\d+_\d+_0/.test(key);
	}
}
