/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, forwardRef, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * IMV image control component
 */
@Component({
	selector: 'imv-image-control',
	templateUrl: './image-control.component.html',
	styleUrls: ['./image-control.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ImageControlComponent),
			multi: true,
		},
	],
})
export class ImageControlComponent implements ControlValueAccessor {
	/**
	 * On change function
	 *
	 * @param _ entry parameter
	 */
	onChange = (res: any) => res;

	/**
	 * On touched function
	 */
	onTouched = () => { };

	/**
	 * Show errors input boolean
	 */
	@Input() showErrors = false;

	/**
	 * Disabled input boolean
	 */
	@Input() disabled = false;

	/**
	 * Required input boolean
	 */
	@Input() required = false;

	/**
	 * On focus output event emitter
	 */
	@Output() onfocus = new EventEmitter<any>();

	/**
	 * Value
	 */
	value: any;

	/**
	 * Triggered when value is changed
	 *
	 * @param event
	 */
	@HostListener('changeValue', ['$event.detail'])
	change(value: any) {
		this.onChange(value);
	}

	/**
	 * Triggered when the file is removed
	 *
	 * @param value
	 */
	@HostListener('fileRemoved', ['$event.detail'])
	fileRemove() {
		this.onChange(null);
	}

	/**
	 * Empty constructor
	 */
	constructor() {
		/**
		 * Empty constructor
		 */
	}



	/**
	 * Sets the value
	 */
	writeValue(value: any): void {
		this.value = value;
	}

	/**
	 * Controls value changes in the user interface
	 */
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	/**
	 * Called when the user interface is touched
	 */
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
		this.onfocus.emit(true);
	}
}
