import { Injectable, NgZone } from '@angular/core';

class Subscription {
	name: string;
	fn: any;
	constructor(name: string, fn: any) {
		this.name = name;
		this.fn = fn;
	}
}
declare global {
	interface Window {
		notify: any;
		subscribe: any;
		unsubscribe: any;
		pubSubListeners: any;
		ft: any;
		eb: any;
		dataLayer: any[];
	}
}

@Injectable()
export class GlobalPubSub {
	listeners: Subscription[];
	constructor(private zone: NgZone) {
		this.listeners = [];
		this.zone.run(() => (window.ft = this));
		window.notify = (name: string, args: any) => this.zone.run(() => this.notify(name, args));
		window.subscribe = (eventName: string, fn: any) => this.subscribe(eventName, fn);
		window.unsubscribe = (eventName: string) => this.unsubscribe(eventName);
	}

	subscribe = (name: string, fn: any) => {
		const index: number = this.listeners.findIndex(listener => listener.name === name && listener.fn.name === fn.name);
		const subscription = new Subscription(name, fn);
		if (index >= 0) {
			this.listeners[index] = subscription;
		} else {
			this.listeners.push(subscription);
		}
	};

	unsubscribe = (name: string) => {
		const index = this.listeners.findIndex(subscription => subscription.name === name);
		this.listeners.splice(index, 1);
	};

	notify(...args: any[]) {
		const name = args.shift();
		this.listeners.forEach(subscription => (subscription.name === name ? subscription.fn.apply(this, args) : ''));
		return this;
	}
}
