/* eslint-disable max-len */
import {
	ParametricalItem,
	Item,
	ParametricalCivilStateItem,
	ParametricalElementItem,
	ParametricalLodgingTypeItem,
	ParametricalCountryItem,
	CountryItem,
} from './common-list.entities';

export class CommonListServiceMapper {
	static mapParametricalItemsToItems = (items: ParametricalItem[]): Item[] => items.map((item: ParametricalItem) => ({ id: item.elemento, name: item.denomLarga }));
	static mapParametricalCivilStateItemsToItems = (items: ParametricalCivilStateItem[]): Item[] =>
		items.map((item: ParametricalCivilStateItem) => ({ id: item.elemento, name: item.estadoCivil }))
			.map((item: Item) => (item.name = item.id === '3' ? 'Pareja de hecho' : item.name, item));
	static mapParametricalElementItemsToItems = (items: ParametricalElementItem[]): Item[] =>
		items.map((item: ParametricalElementItem) => ({ id: item.elemento, name: item.elemento }));
	static mapParametricalLodgingTypeItemsToItems = (items: ParametricalLodgingTypeItem[]): Item[] =>
		items.map((item: ParametricalLodgingTypeItem) => ({ id: item.elemento, name: item.tipoAlojamiento }));
	static mapParametricalCountryItemsToCountryItems = (items: ParametricalCountryItem[]): CountryItem[] =>
		items.map((item: ParametricalCountryItem) => ({ id: item.elemento, name: item.denomLarga, isEspania: item.espania > 0, isEuropa: item.pertenenciaUEEE > 0 }));
}
