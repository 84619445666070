import { Injectable } from '@angular/core';

import { AbstractCommonUtilsService } from './abstract-common-utils.service';

/**
 * Common utils service
 */
@Injectable()
export class CommonUtilsService implements AbstractCommonUtilsService {
	/**
	 * Empty constructor
	 */
	constructor() {
		//Empty constructor
	}

	/**
	 * Gets module name
	 *
	 * @param name name
	 * @returns module name
	 */
	getModuleName(name: string): string {
		return name;
	}
}
