import { Injectable, Injector, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { IAppConfig, APP_CONFIG, ConfigManager, CacheService, IAppEnv } from '@app/core';
import { StepResponses, FormConfig, VerifyCaptcha, VerifyCaptchaResponse, ValidateEmail, ValidateEmailResponse, EmailCaiss } from '@shared/models';
import { FormDto, BaremosDto, RefusalDto, DocumentDto } from '@shared/dtos';

import { AbstractCommonQuestionnaireService } from './abstract-common-questionnaire.service';

import { TokenService } from 'src/app/core/services/auth/token.service';
import { APP_ENVIRONMENT } from 'src/app/core/models/core.model';

interface Answer {
	hasChildren: boolean;
	key: string
	tipo: string
	value: string | any;
}

/**
 * Common Quetsionnaire service
 */
@Injectable()
export class CommonQuestionnaireService implements AbstractCommonQuestionnaireService {
	/**
	 * Contains url for http client endpoints
	 */
	private readonly _httpClient: HttpClient;

	/**
	 * Contains url for request endpoints
	 */
	private readonly _bffSolicitud: string;

	/**
	 * Contains url for questionnaire endpoints
	 */
	private readonly _bffCuestionario: string;

	/**
	 * Contains url for email endpoints
	 */
	private readonly _bffEmail: string;

	/**
	 * Contains url for email auth endpoints
	 */
	private readonly _bffEmailAuth: string;

	/**
	 * Contains url for security endpoints
	 */
	private readonly _bffSecurity: string;

	/**
	 * Contains url for dossier endpoints
	 */
	private readonly _bffExpediente: string;

	/**
	 * Contains url for documment endpoints
	 */
	private readonly _bffDocumento: string;

	/**
	 * Contains url for gangway GISS endpoints
	 */
	private readonly _bffPasarelaGiss: string;

	/**
	 * Contains url for registry endpoints
	 */
	private readonly _bffRegistro: string;

	/**
	 * Token service
	 */
	private _tokenService: TokenService;

	/**
	 * Cache service
	 */
	private _cacheService: CacheService;

	/**
	 * App env
	 */
	private _appEnv: IAppEnv;

	/**
	 * Is CAISS reference
	 */
	private _isCaiss: boolean;

	/**
	 * Contains all data, and sets application configuration hosts
	 *
	 * @param _injector
	 */
	constructor(_injector: Injector) {
		const appConfig: IAppConfig = _injector.get<ConfigManager>(APP_CONFIG).config;
		this._httpClient = _injector.get(HttpClient);
		this._cacheService = _injector.get(CacheService);
		this._tokenService = _injector.get(TokenService);
		this._appEnv = _injector.get(APP_ENVIRONMENT);
		this._isCaiss = appConfig.caiss === 1;

		this._bffSolicitud = appConfig.apiHost.bff + 'solicitud';
		this._bffEmail = appConfig.apiHost.bff + 'validacion-email';
		this._bffCuestionario = appConfig.apiHost.bff + 'cuestionario';
		this._bffSecurity = appConfig.apiHost.bff + 'seguridad';
		this._bffExpediente = appConfig.apiHost.bff + 'expediente';
		this._bffDocumento = appConfig.apiHost.bff + 'documento';
		this._bffPasarelaGiss = appConfig.apiHost.bff + 'pasarelagiss';
		this._bffRegistro = appConfig.apiHost.bff + 'registro';
		this._bffEmailAuth = appConfig.apiHost.bff + 'validacion-email-generico';
	}

	/**
	 * Gets the JSON form with the questions
	 *
	 * @returns JSON form from backend
	 */
	getAllQuestions(): Observable<FormDto> {
		const url = `${this._bffCuestionario}/?idFormulario=PC&idPrestacion=IMV&idioma=es`;
		return this._cacheService.getWithCache(url);
	}

	/**
	 * Gets the JSON form with the preidentification data
	 *
	 * @returns JSON form from backend
	 */
	getPreIdentification(): Observable<FormDto> {
		const url = `${this._bffCuestionario}/?idFormulario=MI&idPrestacion=IMV&idioma=es`;
		return this._cacheService.getWithCache(url);
	}

	/**
	 * Gets the JSON form with the modifications data
	 *
	 * @returns JSON form from backend
	 */
	getModifications(): Observable<FormDto> {
		const url = `${this._bffCuestionario}/?idFormulario=MO&idPrestacion=IMV&idioma=es`;
		return this._cacheService.getWithCache(url);
	}

	/**
	 * Gets the JSON form with the form data
	 *
	 * @param config form configuration
	 * @returns JSON form from backend
	 */
	getFormData(config?: FormConfig): Observable<FormDto> {
		const url = `${this._bffCuestionario}/?idFormulario=PF&idPrestacion=IMV&idioma=es${config?.version ? '&version=' + config.version : ''}`;
		return this._cacheService.getWithCache(url);
	}

	/**
	 * Gets the baremos JSON
	 *
	 * @returns baremos JSON
	 */
	getBaremos(): Observable<BaremosDto> {
		const url = `${this._bffCuestionario}/baremoImv`;
		return this._cacheService.getWithCache(url);
	}

	/**
	 * Gets the refusals JSON
	 *
	 * @param idform form id
	 * @returns
	 */
	getRefusals(idform = 'PC'): Observable<RefusalDto[]> {
		const url = `${this._bffCuestionario}/denegacion?idFormulario=${idform}&idPrestacion=IMV&idioma=es`;
		return this._cacheService.getWithCache(url);
	}

	/**
	 * Verifies captcha
	 *
	 * @param body verify captcha body
	 * @returns
	 */
	verifyCaptcha(body: VerifyCaptcha): Observable<VerifyCaptchaResponse> {
		if (isDevMode()) {
			return of({ success: true });
		} else {
			const url = `${this._bffSecurity}/verifyCaptcha`;

			return this._httpClient.post<any>(url, body);
		}
	}

	/**
	 * Checks against the police service if the ID is valid
	 *
	 * @param docType identification document type
	 * @param docNumber identification document number
	 * @param supportNumber idnetification document support number
	 * @param firstName first name
	 * @param surname surname 1
	 * @param surname2 surname 2
	 * @param saveToken save token
	 * @returns dniauth token
	 */
	dniAuth(docType: string, docNumber: any, supportNumber: any, firstName = '', surname = '', surname2 = '', saveToken = true): Observable<any> {
		const url = `${this._bffSecurity}/dniauth`;
		const defaultSurname2 = this._appEnv.production ? surname2.trim() || 'x' : 'TEST';

		const body = {
			apellido1: surname.trim() || 'x',
			apellido2: defaultSurname2,
			nombre: firstName.trim() || 'x',
			numeroDocumento: docNumber,
			numeroSoporte: supportNumber,
			tipoDocumento: docType === '1' ? 'DNI' : 'NIE',
		};
		return this._httpClient.post<any>(url, body).pipe(
			map(data => data.userToken),
			tap(token => {
				if (!this._isCaiss && saveToken) {
					this._tokenService.setToken(token);
				}
			}),
		);
	}

	/**
	 * Generates CSV and sends email to user
	 *
	 * @param userEmail email we want to check if is verified or we want to send CSV
	 * @returns HttpStatus response, 200 if OK
	 */
	validateEmail(userEmail: string): Observable<any> {
		const url = `${this._bffEmailAuth}/validarEmail`;
		const body = {
			email: userEmail,
		};
		return this._httpClient.post<any>(url, body);
	}

	/**
	 * Sends pair email, CSV to verify email
	 *
	 * @param userEmail email we want to verify
	 * @param csvToken CSV token received on email
	 * @param saveToken save token
	 * @returns String JWT token
	 */
	verifyEmail(userEmail: string, csvToken: any, saveToken = true): Observable<any> {
		const url = `${this._bffEmailAuth}/verificarEmail`;
		const body = {
			email: userEmail,
			csv: csvToken,
		};
		return this._httpClient.post<any>(url, body).pipe(
			map(data => data.token),
			tap(token => {
				if (!this._isCaiss && saveToken) {
					this._tokenService.setToken(token);
				}
			}),
		);
	}

	/**
	 * Checks if email is verified to receive a token.
	 *
	 * @memberof ValidationEmailService
	 */
	recieveTokenValidation(body: ValidateEmail): Observable<ValidateEmailResponse> {
		const url = `${this._bffEmail}`;
		return this._httpClient.post<ValidateEmailResponse>(url, body);
	}

	/**
	 *  Checks if email to consult email
	 *
	 *  @memberof ValidationEmailService
	 */
	checkIsEmailValidated(body: ValidateEmail): Observable<ValidateEmailResponse> {
		const url = `${this._bffEmail}/consulta`;
		return this._httpClient.post<ValidateEmailResponse>(url, body);
	}

	/**
	 * Send token to service for validate email.
	 *
	 * @memberof ValidationEmailService
	 */
	sendTokenToValidate(body: ValidateEmail): Observable<ValidateEmailResponse> {
		const url = `${this._bffEmail}/verificacion`;
		return this._httpClient.post<ValidateEmailResponse>(url, body);
	}

	/**
	 * Sends the draft email when is CAISS
	 *
	 * @param body recovery token
	 * @returns request token
	 */
	sendCaissEmail(body: EmailCaiss): Observable<any> {
		const url = `${this._bffSolicitud}/envioEmailCaiss`;
		return this._httpClient.post<any>(url, body);
	}

	/**
	 * Saves request id, answers, version and isCAISS
	 *
	 * @param id id
	 * @param version form version
	 * @param data data
	 * @returns
	 */
	saveSolicitud(id: string | null, version: number, data: any[]): Observable<any> {
		const url = `${this._bffSolicitud}`;
		const body = {
			id,
			respuestas: this.handleAnswers(data),
			version: `${version}`,
			isCaiss: this._isCaiss,
		};

		return this._httpClient.post<ValidateEmailResponse>(url, body);
	}

	/**
	 * Sends request to request endpoint
	 *
	 * @param id id
	 * @param version form version
	 * @param data data
	 * @returns
	 */
	submitSolicitud(id: string | null, version: number, data: any[]): Observable<any> {
		const url = `${this._bffSolicitud}/presentarSolicitud`;
		const body = {
			id: id || null,
			version: `${version}`,
			respuestas: this.handleAnswers(data),
		};
		return this._httpClient.post<ValidateEmailResponse>(url, body);
	}

	private handleAnswers = (answers: Answer[]) => answers.map(this.handleAnswer);

	private handleAnswer = (answer: Answer) => {
		answer = this.handleWhitespaces(answer, 'PF87');
		answer = this.handleWhitespaces(answer, 'PF54');
		answer = this.handleWhitespaces(answer, 'PF55');
		answer = this.handleWhitespaces(answer, 'MO1');
		answer = this.handleWhitespaces(answer, 'MO2');
		answer = this.handleWhitespaces(answer, 'MO17');

		return answer;
	}

	private handleWhitespaces(answer: Answer, key: string) {
		if (answer.key === key) {
			answer.value = answer.value?.replace(/ /g, '');
		}
		return answer;
	}

	/**
	 * Sends CAISS request to request endpoint
	 *
	 * @param id id
	 * @param version form version
	 * @param data data
	 * @returns
	 */
	submitSolicitudCaiss(id: string | null, version: number, data: any[]): Observable<any> {
		const url = `${this._bffSolicitud}/presentarSolicitudCaiss`;
		const body = {
			id: id || null,
			version: `${version}`,
			respuestas: this.handleAnswers(data),
		};

		return this._httpClient.post<ValidateEmailResponse>(url, body);
	}

	/**
	 * Sends modifications to validation endpoint
	 *
	 * @param idExpedienteFuncional id expediente funcional
	 * @param version form version
	 * @param data data
	 * @returns
	 */
	submitModifications(idExpedienteFuncional: string, version: number, data: any[]): Observable<any> {
		const url = `${this._bffExpediente}/updateExpedientesTramite `;
		const body = {
			idExpedienteFuncional,
			version: `${version}`,
			respuestas: this.handleAnswers(data),
		};

		return this._httpClient.post<any>(url, body);
	}

	/**
	 * Recovers request from recovery request endpoint
	 *
	 * @param dni dni
	 * @param userEmail email token
	 * @param token email
	 * @returns
	 */
	recoverSolicitud(dni: string, userEmail: string, tokenEmail: string): Observable<any> {
		const url = `${this._bffSolicitud}/recuperarSolicitud`;
		//if not found bff micro
		//const url = `https://services-imv.int.portal.ss/imv-core/public/recuperarSolicitud`;
		const body = {
			numDocumento: dni,
			email: userEmail,
			token: tokenEmail,
			lenguaje: 'Es_es',
		};

		return this._httpClient.post<any>(url, body);
	}
	/**
	 * Revocovers CAISS request from recovery request endpoint
	 *
	 * @param dni dni
	 * @returns
	 */
	recoverSolicitudCaiss(dni: string): Observable<any> {
		const body = {
			numDocumento: dni,
		};
		const url = `${this._bffSolicitud}/recuperarSolicitudCaiss`;
		//if not found bff micro
		//const url = `https://services-imv.int.portal.ss/imv-core/public/recuperarSolicitudCaiss`;
		return this._httpClient.post<any>(url, body);
	}

	/**
	 * Sends documentation to endpoint
	 *
	 * @param document
	 * @returns
	 */
	createDocument(document: any): Observable<DocumentDto> {
		const url = `${this._bffDocumento}`;
		const body = {
			nombre: document.nombre,
			contenido: document.contenido,
		};

		return this._httpClient.post<DocumentDto>(url, body);
	}

	/**
	 * Deletes document on endpoint
	 *
	 * @param id
	 * @returns
	 */
	deleteDocument(id: string) {
		const url = `${this._bffDocumento}/${id}`;

		return this._httpClient.delete<any>(url);
	}

	/**
	 * Gets document from endpoint
	 *
	 * @param body
	 * @returns
	 */
	getDocument(body: any) {
		const url = `${this._bffDocumento}/`;

		return this._httpClient.post<any>(url, body);
	}

	/**
	 * Gets CAISS document from endpoint
	 *
	 * @param id id
	 * @returns
	 */
	getDocumentCaiss(id: any) {
		const url = `${this._bffDocumento}/${id}`;
		return this._httpClient.get<any>(url);
	}

	/**
	 * Navigates url to download document
	 *
	 * @param id id
	 * @returns
	 */
	downloadDocument(id: string) {
		const url = `${this._bffSolicitud}/generarPDFSolicitud?idSolicitud=${id}`;

		return this._httpClient.get<any>(url, { observe: 'body' });
	}

	/**
	 * Navidates url to download document with version param
	 *
	 * @param id id
	 * @param version form version
	 * @returns
	 */
	downloadDocumentIdsga(id: string, version: string) {
		const url = `${this._bffExpediente}/descargarDocumento?idSgda=${id}&versionSGDA=${version}`;

		return this._httpClient.get<any>(url);
	}

	/**
	 * Navigates to validate form step
	 *
	 * @param responses step responses
	 * @returns
	 */
	validateFormStep(responses: StepResponses) {
		responses.respuestas = this.handleAnswers(responses.respuestas);
		const url = `${this._bffSolicitud}/validarFormularioPaso`;
		return this._httpClient.post<any>(url, responses);
	}

	/**
	 * Validates modifications
	 *
	 * @param responses step responses
	 * @returns
	 */
	validateModifications(responses: StepResponses) {
		responses.respuestas = this.handleAnswers(responses.respuestas);
		const url = `${this._bffSolicitud}/validacionFormularioPrevia`;
		return this._httpClient.post<any>(url, responses);
	}

	/**
	 * Navigates to  previous claim
	 *
	 * @param form form
	 * @returns
	 */
	setPreviousClaim(form: any): Observable<any> {
		const url = `${this._bffExpediente}/envioReclamacionPrevia`;
		return this._httpClient.post<DocumentDto>(url, form);
	}

	/**
	 * Gets proceedings documents
	 *
	 * @param id id
	 * @returns
	 */
	getProceedingsDocuments(id: string): Observable<any> {
		const url = `${this._bffExpediente}/documentos/` + id;
		return this._httpClient.get<any>(url);
	}

	/**
	 * Sends new previous claim document to endpoint
	 *
	 * @param document document
	 * @returns
	 */
	sendNewPreviousClaimDocument(document: any): Observable<any> {
		const url = `${this._bffExpediente}/adjuntarOtraDocumentacionPrevia`;
		return this._httpClient.post<any>(url, document);
	}

	/**
	 * Sends no repudio to endpoint
	 *
	 * @param body
	 * @returns
	 */
	sendNoRepudio(body: any): Observable<any> {
		const url = `${this._bffSolicitud}/envioNoRepudioV2`;
		return this._httpClient.post<any>(url, body);
	}

	/**
	 * Sends verificate no repudio code to endpoint
	 *
	 * @param body request body
	 * @returns
	 */
	verificateNoRepudio(body: any): Observable<any> {
		const url = `${this._bffSolicitud}/verificacionNoRepudio`;
		return this._httpClient.post<any>(url, body);
	}

	/**
	 *  Sends duplications checks to endpoint
	 *
	 * @param body request body
	 * @returns
	 */
	checkDuplication(body: any): Observable<any> {
		const url = `${this._bffSolicitud}/comprobarDuplicidades`;
		return this._httpClient.post<any>(url, body);
	}

	/**
	 * Sends expirated request to endpoint
	 *
	 * @param data request data
	 * @returns
	 */
	deleteSolicitudes(data: any): Observable<any> {
		const url = `${this._bffSolicitud}/expiraSolicitudesSolicitante`;
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			body: data,
		};
		return this._httpClient.delete<any>(url, httpOptions);
	}

	/**
	 * Sends videoidentification qr to endpoint
	 *
	 * @param width width
	 * @param height height
	 * @returns
	 */
	getQrVideoidentification(width: number, height: number): Observable<any> {
		const url = `${this._bffSolicitud}/obtenerQrVideoIdentificacion`;

		return this._httpClient.post<any>(url, { width, height });
	}

	/**
	 * Sends videoidentification photos to web socket endpoint
	 *
	 * @param body request body
	 * @returns
	 */
	sendVideoidentificationPhotoWs(body: any): Observable<any> {
		const url = `${this._bffSolicitud}/enviarDocumentoClienteWebsocket`;

		return this._httpClient.post<any>(url, body);
	}

	/**
	 * Gets videoidentification photos from web sockets endpoint
	 *
	 * @param id id
	 * @returns
	 */
	getVideoidentificationPhoto(id: string): Observable<any> {
		const url = `${this._bffSolicitud}/obtenerDocumentoClienteWebsocket?id=` + id;

		return this._httpClient.get<any>(url);
	}

	/**
	 * Verifies CAISS registry number
	 *
	 * @param numeroRegistro registry number string
	 * @returns
	 */
	verifyCaissRegistry(numeroRegistro: string): Observable<any> {
		const url = `${this._bffSolicitud}/validarRegistro?numeroRegistro=${numeroRegistro}`;
		return this._httpClient.get<any>(url);
	}
}
